import { IWorkflowExternalVideoElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useCallback, useEffect, useState } from 'react';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import VimeoPlayer, { EndEvent } from '@u-wave/react-vimeo';

const VimeoExternalVideoWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowExternalVideoElement>> = (props) => {
  const { workflowElement, onValid } = props;
  const [watched, setWatched] = useState(false);

  const onEnd = useCallback((e: EndEvent) => {
    setWatched(true);
  }, []);

  useEffect(() => {
    if (onValid) {
      onValid(workflowElement, watched);
    }
  }, [onValid, watched, workflowElement]);

  return (
    <div className="vimeo-external-video-workflow-element">
      <VimeoPlayer
        video={workflowElement.videoId}
        onEnd={onEnd}
        volume={1}
      />
    </div>
  );
};

export default VimeoExternalVideoWorkflowElement;
