import { IWorkflowExternalVideoElement } from 'lib/modules/qualieApi/enities/workflow';
import { Dictionary } from 'lib/types';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import VimeoExternalVideoWorkflowElement from './vimeo';
import WistiaExternalVideoWorkflowElement from './wistia';

enum WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPES {
  Wistia = 'WISTIA',
  Vimeo = 'VIMEO',
};

const WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPE_COMPONENTS: Dictionary<React.FunctionComponent<IWorkflowElementProps<IWorkflowExternalVideoElement>>> = {
  [WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPES.Wistia]: WistiaExternalVideoWorkflowElement,
  [WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPES.Vimeo]: VimeoExternalVideoWorkflowElement,
};

export {
  WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPES,
  WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPE_COMPONENTS,
};
