import _ from 'lodash/fp';
import { IWorkflowElement, IWorkflowFormElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useCallback } from 'react';
import WorkflowElement from '../../..';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import { useAppDispatch } from 'app/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { message } from 'antd';
import { Dictionary } from 'lib/types';

type Dependencies = {
  qualieCamOptions: IWorkflowElement;
};

const buildPayload = (elements: IWorkflowElement[], url: string) => elements.reduce<Dictionary>((acc, curr) => {
  switch (curr.name) {
    case 's3Key':
      acc[curr.name] = url;
      break;
    case 'opinionActivities':
      acc[curr.name] = curr.collection?.length ? _.values(buildPayload(curr.collection, url)) : [];
      break;
    default:
      acc[curr.name] = curr.collection?.length ? buildPayload(curr.collection, url) : curr.value;
  }

  return acc;
}, {});

const RecordOpinionQcFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement, Dependencies>> = ({
  workflowElement,
  dependencies,
  onBeforeFinish,
  onFinish,
}) => {
  const dispatch = useAppDispatch();

  const onRecordingFinish = useCallback(async (element: IWorkflowElement, url: string) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    const payload = buildPayload(workflowElement.elements, url);

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: payload,
      })).unwrap();
    } catch (e: any) {
      message.error(e.message, 3);

      return;
    }

    onFinish?.(workflowElement);
  }, [dispatch, onBeforeFinish, onFinish, workflowElement]);

  return (
    <WorkflowElement
      workflowElement={dependencies!.qualieCamOptions!}
      onFinish={onRecordingFinish}
    />
  );
};

export default RecordOpinionQcFormWorkflowElement;
