import { useAppSelector, useExternalScript, useMountEffect } from 'app/hooks';
import { puxSelectors } from 'app/modules/pux/store';
import { IWorkflowExternalVideoElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import cx from 'classnames';

const getVideo = (videoId: string) => window?.Wistia?.api(videoId);
const isVideoComplete = (video: any) => (video.time() / video.duration()) > 0.90;

const WistiaExternalVideoWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowExternalVideoElement>> = (props) => {
  const mounted = useRef(true);
  const { workflowElement, onValid } = props;
  const host = useAppSelector(state => puxSelectors.getHost(state, 'wistia'));
  const [video, setVideo] = useState<any>();
  const showVideoControls = useAppSelector(puxSelectors.getShowVideoControls);
  const [watched, setWatched] = useState(false);

  /**
   * Now that one of our scripts is loaded we should try and get the video
   * out of the Wistia API.
   */
  const onVideoScriptLoad = useCallback(() => {
    if (mounted.current) {
      setVideo(getVideo(workflowElement.videoId));
    }
  }, [workflowElement]);

  /**
   * Our video changed we should retarget to the new video from the Wistia API.
   */
  useEffect(() => {
    setVideo(getVideo(workflowElement.videoId));
  }, [workflowElement]);

  useEffect(() => {
    if (watched) {
      return;
    }

    const pollingHandle = setInterval(() => {
      if (video?.embedded()) {
        if (isVideoComplete(video)) {
          setWatched(true);
        }
      } else {
        const nextVideo = getVideo(workflowElement.videoId);
        if (nextVideo !== video) {
          setVideo(nextVideo);
        }
      }
    }, 1000);

    return () => {
      clearInterval(pollingHandle);
    };
  }, [video, watched, setWatched, setVideo, workflowElement.videoId]);

  useEffect(() => {
    if (onValid) {
      onValid(workflowElement, watched);
    }
  }, [workflowElement, watched, onValid]);

  useExternalScript('https://fast.wistia.com/assets/external/E-v1.js', false, onVideoScriptLoad);
  // We need to destroy the jsonp video script on unmount to clean up properly!
  useExternalScript(`${host}/embed/medias/${workflowElement.videoId}.jsonp`, true, onVideoScriptLoad);

  useMountEffect(() => () => {
    mounted.current = false;
  });

  return (
    <div className="wistia-external-video-workflow-element" >
      {!!workflowElement.videoId && (
        <div
          className={cx({
            'wistia_embed': true,
            [`wistia_async_${workflowElement.videoId}`]: true,
            'playbar=false': !showVideoControls,
            'hsl=false': true,
          })}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </div >
  );
};

export default WistiaExternalVideoWorkflowElement;
