import _ from 'lodash';
import { Form, Alert, Button, FormProps } from 'antd';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowFormElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import FormElement from '../formElement';
import { useAppDispatch } from 'app/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { useIntl } from 'react-intl';
import QualieAPI from 'lib/modules/qualieApi';
import FormActions from 'app/modules/pux/components/layout/formActions';
import { FormElementContext, IFormElementContext } from 'app/modules/pux/contexts';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '../formElement/types';
import Utils from 'lib/utils';
import { Dictionary } from 'lib/types';

const RankingOpinionFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>> = (props) => {
  const { dependencies, workflowElement, onFinish, onBeforeFinish } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const initialValues = useMemo(() => elements.reduce<Dictionary>((acc, curr) => {
    // reserved field names get their values elsewhere
    switch (curr.name) {
      case 'opinionActivities':
        const fields = (curr.collection || []).reduce<Dictionary>((acc, curr) => {
          if (curr.collection) {
            acc[curr.name] = curr.collection.reduce<Dictionary>((acc, curr) => {
              switch (curr.name) {
                case 'rank':
                  acc[curr.name] = null;
                  break;
                default:
                  acc[curr.name] = curr.value;
                  break;
              }

              return acc;
            }, {});
          } else {
            acc[curr.name] = curr.value;
          }

          return acc;
        }, {});

        acc[curr.name] = fields;
        break;
      default:
        acc[curr.name] = curr.value;
    }

    return acc;
  }, {}), [elements]);
  const typeMapper = useCallback((element: IWorkflowFormElementElement) => {
    if (element.name === 'opinionActivities' && element.inputType === WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Collection) {
      return WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.RankingOpinion;
    }

    return undefined;
  }, []);
  const formElementContextValue: IFormElementContext = useMemo(() => ({
    typeMapper: typeMapper,
  }), [typeMapper]);

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    const parsedValues = {
      ...values,
      opinionActivities: _.map(values.opinionActivities, (value, hash) => ({
        opinionType: 'RANKING',
        initiativeHash: hash,
        type: 'com.qualie.api.dto.RankingOpinionActivityDTO',
        participantHash: values.participantHash,
        rank: `${value.rank}`,
      })),
    };

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, (parsedValues.opinionActivities as any[]).map((a, i) => ({
            from: `opinionActivities[${i}]`,
            to: `opinionActivities.${a.initiativeHash}`,
          }))));
          break;
      }
      setError(e?.message || intl.formatMessage({ id: 'workflowElement.form.genericServerError' }));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [intl, workflowElement, dispatch, setFields, onFinish, onBeforeFinish]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <FormElementContext.Provider value={formElementContextValue}>
      <Form
        layout="vertical"
        onFinish={onFormFinish}
        initialValues={initialValues}
        fields={fields}
      >
        {!!error && (
          <Alert
            type="error"
            message={error}
            showIcon
          />
        )}
        {elements.map(a => (
          <FormElement
            dependencies={dependencies}
            key={a.name}
            workflowElement={a}
          />
        ))}
        <FormActions>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={submitted}
            >{workflowElement.submitButtonText}</Button>
          </Form.Item>
        </FormActions>
      </Form >
    </FormElementContext.Provider>
  );
};
export default RankingOpinionFormWorkflowElement;
