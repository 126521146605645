import { Alert } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import WorkflowElement from '../workflowElement';
import IWorkflowActivityProps from './IWorkflowActivityProps';
import { WORKFLOW_ACTIVITY_TYPE_COMPONENTS } from './types';

const InvalidTypeFallbackWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const intl = useIntl();

  return (
    <div>
      <Alert
        type="error"
        message={intl.formatMessage({ id: 'workflowActivity.invalidTypeFallback.title' }, { type: workflowActivity.workflowActivityType })}
        showIcon
      />
      {workflowActivity.workflowElements?.map(workflowElement => (
        <div key={workflowElement.name}>
          <WorkflowElement workflowElement={workflowElement} />
        </div>
      ))}
    </div>
  );
};

const WorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const WorkflowActivityType = React.useMemo(() => WORKFLOW_ACTIVITY_TYPE_COMPONENTS[workflowActivity.workflowActivityType] || InvalidTypeFallbackWorkflowActivity, [workflowActivity]);

  return <WorkflowActivityType {...props} />;
};

export default WorkflowActivity;
