import _ from 'lodash';
import { Dictionary } from './types';

const scrollToTop = () => {
  if (window.scroll) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
};

/**
 * Returns a promise that resolves after the provided time.
 * A handy function when you need to perform a timeout or wait
 * in an async function.
 */
const wait = (ms: number) => new Promise((resolve) => {
  setTimeout(() => {
    resolve(undefined);
  }, ms);
});

/**
 * Maps Qualie API errors into a structure that is compatible with
 * the forms in the user interface.
 */
const apiToFormErrors = (errors: Dictionary<string>, overrides?: { from: string, to: string }[]) => {
  let errorsWithOverrides: typeof errors = {};

  if (overrides) {
    for (let key in errors) {
      let finalKey = key;
      for (let i = 0; i < overrides.length; i++) {
        if (key.startsWith(overrides[i].from)) {
          finalKey = key.replace(overrides[i].from, overrides[i].to);
          break;
        }
      }
      errorsWithOverrides[finalKey] = errors[key];
    }
  }

  return _.map(errorsWithOverrides, (v, k) => ({
    name: k.split('.'),
    errors: [v],
  }));
};

const Utils = {
  scrollToTop: scrollToTop,
  wait,
  apiToFormErrors,
};

export default Utils;
