import { Alert, Button, Form, FormProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch } from 'app/hooks';
import FormActions from 'app/modules/pux/components/layout/formActions';
import { FormElementContext, IFormElementContext } from 'app/modules/pux/contexts';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { workflowsActions } from 'app/modules/workflows';
import QualieAPI from 'lib/modules/qualieApi';
import { IWorkflowFormElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/enities/workflow';
import { Dictionary } from 'lib/types';
import Utils from 'lib/utils';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import FormElement from '../formElement';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '../formElement/types';

const QuestionsMatrixFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>> = (props) => {
  const { workflowElement, onFinish, onBeforeFinish } = props;
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const [submitted, setSubmitted] = useState(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const [form] = useForm();
  const initialValues = useMemo(() => elements.reduce<Dictionary>((acc, curr) => {
    switch (curr.name) {
      default:
        acc[curr.name] = curr.value;
    }

    return acc;
  }, {}), [elements]);
  const typeMapper = useCallback((element: IWorkflowFormElementElement) => {
    if (element.name === 'questionAnswers' && element.inputType === WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Collection) {
      return WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Matrix;
    }

    return undefined;
  }, []);
  const formElementContextValue: IFormElementContext = useMemo(() => ({
    form: form,
    typeMapper: typeMapper,
  }), [form, typeMapper]);

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    const questionAnswers = _.keyBy(elements.find(element => element.name === 'questionAnswers')?.collection, 'name');
    const parsedValues = {
      ...values,
      questionAnswers: _.map(values.questionAnswers, (value, key) => {
        const question = questionAnswers[key];

        let selectedAnswers: any = {};
        // This code maps the provided form structure to what the API expects.
        if (question?.options) {
          if (_.isArray(value) || _.isString(value)) {
            selectedAnswers = (_.isArray(value) ? value : [value]).reduce<Dictionary<string>>((acc, v) => {
              const option = question.options?.find(a => a.key === v);
              acc[v] = option ? option.value : v;

              return acc;
            }, {});
          } else if (_.isObject(value)) {
            _.keys(value).forEach((valueKey => {
              selectedAnswers[valueKey] = _.find(question.options, { key: valueKey })?.value;
            }));
          }
        }

        return {
          questionHash: key,
          selectedAnswers: selectedAnswers,
        };
      }),
    };

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, []));
          break;
      }
      setError(e?.message || intl.formatMessage({ id: 'workflowElement.form.genericServerError' }));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [elements, intl, workflowElement, dispatch, setFields, onFinish, onBeforeFinish]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <FormElementContext.Provider value={formElementContextValue}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormFinish}
        initialValues={initialValues}
        fields={fields}
      >
        {!!error && (
          <Alert
            type="error"
            message={error}
            showIcon
          />
        )}
        {elements.map(a => (
          <FormElement
            key={a.name}
            workflowElement={a}
          />
        ))}
        <FormActions>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={submitted}
            >{workflowElement.submitButtonText}</Button>
          </Form.Item>
        </FormActions>
      </Form>
    </FormElementContext.Provider>
  );
};

export default QuestionsMatrixFormWorkflowElement;