import { Alert } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import IWorkflowElementProps from './IWorkflowElementProps';
import { WORKFLOW_ELEMENT_TYPE_COMPONENTS } from './types';

interface IProps extends IWorkflowElementProps {
  asType?: keyof typeof WORKFLOW_ELEMENT_TYPE_COMPONENTS;
}

const InvalidTypeFallbackWorkflowElement: React.FunctionComponent<IWorkflowElementProps> = (props) => {
  const { workflowElement } = props;
  const intl = useIntl();

  return (
    <React.Fragment>
      <Alert
        type="error"
        message={intl.formatMessage({ id: 'workflowElement.invalidTypeFallback.title' }, { type: workflowElement.workflowElementType })}
        showIcon
      />
      {workflowElement.collection?.map(workflowElement => (
        <div key={workflowElement.name}>
          <WorkflowElement workflowElement={workflowElement} />
        </div>
      ))}
    </React.Fragment>
  );
};

const WorkflowElement: React.FunctionComponent<IProps> = (props) => {
  const { workflowElement, asType } = props;
  const WorkflowElementType = React.useMemo(() => WORKFLOW_ELEMENT_TYPE_COMPONENTS[asType || workflowElement.workflowElementType] || InvalidTypeFallbackWorkflowElement, [workflowElement, asType]);

  return <WorkflowElementType {...props} />;
};

export default WorkflowElement;
