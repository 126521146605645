import { RouteComponentProps, StaticContext } from 'react-router';
import appRoutes from 'app/routes';
import React, { useCallback, useEffect, useMemo } from 'react';
import { workflowsActions, workflowsSelectors } from 'app/modules/workflows/store';
import { useAppDispatch, useAppSelector, useMountEffect, useQueryParams } from 'app/hooks';
import { WorkflowActivity, Footer, RouteTransition } from '../components';
import { push, replace } from 'connected-react-router';
import { CommencedWorkflowContext } from '../contexts';
import { Layout, Progress } from 'antd';
import { useCommencedWorkflowContextValue } from 'app/modules/workflows/hooks';
import Theme from '../components/theme';

interface IProps extends RouteComponentProps<typeof appRoutes['routes']['workflowElementStep']['params'], StaticContext, { noVerify?: boolean } | undefined> { }

const WorkflowElementStepRoute: React.FunctionComponent<IProps> = (props) => {
  const { match, location } = props;
  const queryParams = useQueryParams<typeof appRoutes['routes']['workflowElementStep']['query']>();
  const dispatch = useAppDispatch();
  const commencedWorkflowStep = useAppSelector(state => workflowsSelectors.getWorkflowStep(state, (Number)(match.params!.position)));
  const commencedWorkflowContextValue = useCommencedWorkflowContextValue(match.params?.workflowId, (Number)(match.params?.position));
  const progress = useMemo(() => ((commencedWorkflowStep?.position || 1) / (commencedWorkflowStep?.flowActivities || 1) * 100), [commencedWorkflowStep]);
  const furthestWorkflowStep = useAppSelector(workflowsSelectors.getFurthestWorkflowStep);

  const onVerifyParticipantStep = useCallback(async () => {
    if (location.state?.noVerify) {
      return;
    }

    if (furthestWorkflowStep != null) {
      if (furthestWorkflowStep.position > (Number)(match.params?.position) || furthestWorkflowStep.workflowActivityType !== match.params?.workflowActivityType) {
        dispatch(push(appRoutes.route('workflowElementStep', {
          workflowId: match.params!.workflowId,
          workflowActivityType: furthestWorkflowStep.workflowActivityType,
          position: (String)(furthestWorkflowStep.position),
        }, {})));
      }
    } else if (commencedWorkflowContextValue.participant) {
      const result = await dispatch(workflowsActions.getNextWorkflowStep({
        workflowId: match.params!.workflowId,
        participantId: commencedWorkflowContextValue.participant.hash,
      })).unwrap();

      if ((Number)(result.data?.position) !== (Number)(match.params?.position) || result.data?.workflowActivityType !== match.params?.workflowActivityType) {
        dispatch(push(appRoutes.route('workflowElementStep', {
          workflowId: match.params!.workflowId,
          workflowActivityType: result.data.workflowActivityType,
          position: (String)(result.data.position),
        }, {})));
      }
    }
  }, [dispatch, commencedWorkflowContextValue.participant, match.params, location.state, furthestWorkflowStep]);

  useEffect(() => {
    onVerifyParticipantStep();
  }, [onVerifyParticipantStep]);

  useMountEffect(() => {
    if (!commencedWorkflowContextValue.participant && !commencedWorkflowStep) {
      dispatch(workflowsActions.commenceWorkflow({
        workflowId: match.params!.workflowId,
        options: {
          cookie: queryParams?.cookie,
          flowName: queryParams?.flowName || 'OFF_CAM',
          pp: queryParams?.pp || 'QLE',
          enableUpload: queryParams?.enableUpload,
          referrer: queryParams?.ref,
          uid: queryParams?.uid,
        },
      }));
      dispatch(replace(appRoutes.route('workflowElementStep', match.params!, {})));
    } else {
      onVerifyParticipantStep();
    }
  });

  return (
    <Layout className="root-layout">
      <Theme />
      <Progress
        className="global-progress"
        percent={progress}
        showInfo={false}
        status="active"
      />
      <Layout.Content className="activity-layout">
        <RouteTransition reveal={commencedWorkflowContextValue.ready}>
          <CommencedWorkflowContext.Provider value={commencedWorkflowContextValue}>
            {!!commencedWorkflowStep && (
              <WorkflowActivity workflowActivity={commencedWorkflowStep} />
            )}
          </CommencedWorkflowContext.Provider>
        </RouteTransition>
      </Layout.Content>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout>
  );
};

export default WorkflowElementStepRoute;
