import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IWorkflowFormElementProps } from './types';
import { Form, Slider } from 'antd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import ErrorMessage from '../../../errorMessage';

const ThemometerFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const sortedOptions = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement.options]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [dirty, setDirty] = useState<boolean>(false);
  const handleStyles = useMemo(() => {
    let styles: React.CSSProperties = {};
    if (!dirty) {
      styles['display'] = 'none';
    }

    return styles;
  }, [dirty]);

  const onAfterChange = useCallback((value: number) => setDirty(true), [setDirty]);

  return (
    <Form.Item
      className="thermometer-form-element"
      name={name}
      rules={[
        {
          required: workflowElement.required,
          message: <ErrorMessage message={uiTexts?.NEED_ANSWER?.value} />,
        },
      ]}
    >
      <Slider
        dots={true}
        tooltipVisible={false}
        min={1}
        max={sortedOptions.length}
        handleStyle={handleStyles}
        onAfterChange={onAfterChange}
      />
    </Form.Item>
  );
};

export default ThemometerFormElement;
