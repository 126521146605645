import _ from 'lodash/fp';
import { Alert, Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { IWorkflowCarouselElement, IWorkflowCarouselSlideElement, IWorkflowElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IWorkflowElementProps from '../IWorkflowElementProps';
import { useIntl } from 'react-intl';
import ExternalVideoWorkflowElement from './externalVideo';
import ImageWorkflowElement from './image';
import { Dictionary } from 'lib/types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';

enum CAROUSEL_SLIDE_TYPES {
  Video = 'VIDEO',
  Image = 'IMAGE',
};

const ARROW_TYPES = {
  'left': LeftOutlined,
  'right': RightOutlined,
};

interface ICarouselArrowProps {
  type: keyof typeof ARROW_TYPES;
  currentSlide?: number;
  onClick?: () => void;
  className?: string;
}

const CarouselArrow: React.FunctionComponent<ICarouselArrowProps> = (props) => {
  const { type, className, onClick } = props;
  const Arrow = ARROW_TYPES[type];

  return (
    <div
      className={className}
      onClick={onClick}
    >
      <Arrow className="carousel-arrow" />
    </div>
  );
};

const CarouselSlide: React.FunctionComponent<{
  slide: IWorkflowCarouselSlideElement,
  onValid: IWorkflowElementProps['onValid'],
  active: boolean,
}> = (props) => {
  const { slide, onValid, active } = props;
  const intl = useIntl();

  switch (slide.type) {
    case CAROUSEL_SLIDE_TYPES.Video:
      return (
        <div className="workflow-element-carousel-slide">
          <ExternalVideoWorkflowElement
            workflowElement={slide as any}
            onValid={onValid}
          />
        </div>
      );
    case CAROUSEL_SLIDE_TYPES.Image:
      return (
        <div className="workflow-element-carousel-slide">
          <ImageWorkflowElement
            workflowElement={slide as any}
            onValid={onValid}
            active={active}
          />
        </div>
      );
    default:
      return (
        <div className="workflow-element-carousel-slide">
          <Alert
            type="error"
            message={intl.formatMessage({ id: 'workflowElement.invalidTypeFallback.title' }, { type: slide.type })}
          />
        </div>
      );
  }
};

const getInitialElements = (carouselHtmlElements: IWorkflowCarouselSlideElement[]) => {
  let initialElements: Dictionary<boolean> = {};
  carouselHtmlElements.forEach(htmlElement => {
    initialElements[htmlElement.position] = false;
  });

  return initialElements;
};

const CarouselWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowCarouselElement>> = (props) => {
  const { workflowElement, onValid } = props;
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const carouselHtmlElements = useMemo(() => _.sortBy('position', workflowElement.carouselHtmlElements || []), [workflowElement]);
  const initialElements = useMemo(() => getInitialElements(workflowElement.carouselHtmlElements), [workflowElement]);
  const [validElements, setValidElements] = useState<Dictionary<boolean>>(initialElements);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const afterChange = useCallback((currentSlide: number) => {
    setActiveIndex(currentSlide);
  }, []);

  const onValidSlide = useCallback((workflowElement: IWorkflowElement, valid: boolean) => {
    setValidElements(validElements => ({
      ...validElements,
      [workflowElement.position!]: valid,
    }));
  }, [setValidElements]);

  useEffect(() => {
    if (onValid) {
      const isValid = !!validElements && _.values(validElements).filter(a => !a).length === 0;
      const nextUnviewed = carouselHtmlElements.find(item => !validElements[item.position]);

      let errorMessage = undefined;
      switch (nextUnviewed?.type) {
        case CAROUSEL_SLIDE_TYPES.Video:
          errorMessage = uiTexts?.WATCH_EXPLAINER_VIDEO.value!;
          break;
        case CAROUSEL_SLIDE_TYPES.Image:
          errorMessage = `${uiTexts?.VIEW_IMAGE_ON_SLIDE.value!} ${nextUnviewed?.position}`;
          break;
      }

      onValid(workflowElement, isValid, errorMessage);
    }
  }, [workflowElement, validElements, onValid, carouselHtmlElements, uiTexts]);

  return (
    <div className="workflow-element-carousel">
      <Carousel
        afterChange={afterChange}
        className={carouselHtmlElements.length > 1 ? 'with-arrows' : undefined}
        dotPosition="top"
        dots={carouselHtmlElements.length > 1}
        arrows={carouselHtmlElements.length > 1}
        autoplay={false}
        prevArrow={<CarouselArrow type="left" />}
        nextArrow={<CarouselArrow type="right" />}
        slidesToShow={1}
        infinite={false}
      >
        {carouselHtmlElements.map((element, index) => (
          <CarouselSlide
            key={element.position}
            slide={element}
            onValid={onValidSlide}
            active={index === activeIndex}
          />
        ))}
      </Carousel>
    </div>
  );
};

export {
  ARROW_TYPES,
  CAROUSEL_SLIDE_TYPES,
};

export default CarouselWorkflowElement;
