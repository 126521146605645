import { Button } from 'antd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowCarouselElement, IWorkflowElement, IWorkflowFormElement, IWorkflowInitiativeElement } from 'lib/modules/qualieApi/enities/workflow';
import _ from 'lodash/fp';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { WorkflowActivitySubtitle, WorkflowActivityTitle } from '../../content';
import BasicWorkflowForm from '../../layout/basicWorkflowForm';
import FormActions from '../../layout/formActions';
import SplitWorkflowForm from '../../layout/splitWorkflowForm';
import PrerequisiteError from '../../prerequisiteError';
import WorkflowElement from '../../workflowElement';
import BannerWorkflowElement from '../../workflowElement/types/contentBanner';
import ScaleOpinionMultiFormWorkflowElement from '../../workflowElement/types/form/types/scaleOpinionMulti';
import InitiativeWorkflowElement from '../../workflowElement/types/initiative';
import IWorkflowActivityProps from '../IWorkflowActivityProps';

type Elements = {
  explainer: IWorkflowCarouselElement;
  explainerButtonText: IWorkflowElement;
  initiatives: IWorkflowInitiativeElement;
  scaleOpinionForm: IWorkflowFormElement;
  showPlayerControls: IWorkflowElement;
  title: IWorkflowElement;
  subTitle: IWorkflowElement;
};

const ScaleOpinionMultiWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const { setPrerequisite, uiTexts, checkPrerequisites, toNextStep } = useContext(CommencedWorkflowContext);
  const [explainerComplete, setExplainerComplete] = useState(false);
  const explainers = useMemo(() => _.sortBy('position', elements.explainer?.carouselHtmlElements || []), [elements]);
  const initiatives = useMemo(() => ({
    ...elements.initiatives,
    collection: _.filter((a: any) => a.stimuli.length > 0, elements?.initiatives?.collection || []),
  }), [elements]);

  const showExplainer = !explainerComplete && !!elements.explainer;
  const dependencies = useMemo(() => ({
    initiatives: elements.initiatives,
  }), [elements]);

  const onExplainerValid = useCallback((element: IWorkflowElement, valid: boolean, errorMessage?: string) => {
    setPrerequisite(element.name, valid ? null : {
      valid: valid,
      error: (!valid && (errorMessage || uiTexts?.VIEW_ALL_EXPLAINER_SLIDES?.value)) || null,
      reveal: false,
    });
  }, [setPrerequisite, uiTexts]);

  const onExplainerFinish = useCallback(() => {
    if (checkPrerequisites()) {
      setExplainerComplete(true);
    }
  }, [checkPrerequisites]);

  const onInitiativesValid = useCallback((element: IWorkflowElement, valid: boolean) => {
    setPrerequisite(element.name, valid ? null : {
      valid: valid,
      error: (!valid && uiTexts?.VIEW_ALL_CONTENT?.value) || null,
      reveal: false,
    });
  }, [setPrerequisite, uiTexts]);

  return (
    <SplitWorkflowForm focus="empty">
      <SplitWorkflowForm.Filled>
        <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
        <WorkflowActivitySubtitle>{elements.subTitle.value}</WorkflowActivitySubtitle>
      </SplitWorkflowForm.Filled>
      <SplitWorkflowForm.Empty header={!showExplainer && !!elements.explainer && <BannerWorkflowElement explainers={explainers} />}>
        {showExplainer && (
          <React.Fragment>
            <WorkflowElement
              workflowElement={elements.explainer}
              onValid={onExplainerValid}
            />
            <PrerequisiteError target="explainer" />
            <FormActions>
              <Button
                type="primary"
                onClick={onExplainerFinish}
              >{elements.explainerButtonText.value}</Button>
            </FormActions>
          </React.Fragment>
        )}
        {!showExplainer && (
          <React.Fragment>
            <InitiativeWorkflowElement
              workflowElement={initiatives}
              onValid={onInitiativesValid}
            />
            <BasicWorkflowForm size="default">
              <PrerequisiteError target="initiatives" />
              <ScaleOpinionMultiFormWorkflowElement
                dependencies={dependencies}
                workflowElement={elements.scaleOpinionForm}
                onFinish={toNextStep}
                onBeforeFinish={checkPrerequisites}
              />
            </BasicWorkflowForm>
          </React.Fragment>
        )}
      </SplitWorkflowForm.Empty>
    </SplitWorkflowForm>
  );
};

export default ScaleOpinionMultiWorkflowActivity;