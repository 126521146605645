import React from 'react';
import IWorkflowActivityProps from '../IWorkflowActivityProps';
import DetailsWorkflowActivity from './details';
import GoodbyeWorkflowActivity from './goodbye';
import RankingOpinionWorkflowActivity from './rankingOpinion';
import RecordOpinionCtWorkflowActivity from './recordOpinionCt';
import ReviewVideoWorkflowActivity from './reviewVideo';
import ThankYouV3WorkflowActivity from './thankYouV3';
import WarmupTestCtWorkflowActivity from './warmupTestCt';
import WelcomeV4WorkflowActivity from './welcomeV4';
import AutoSubmitWorkflowActivity from './autoSubmit';
import QuestionsWorkflowActivity from './questions';
import MaterialsWorkflowActivity from './materials';
import QuestionsOpinionWorkflowActivity from './questionsOpinion';
import RankingNpsOpinionWorkflowActivity from './rankingNpsOpinion';
import ScaleOpinionWorkflowActivity from './scaleOpinion';
import ScaleOpinionMultiWorkflowActivity from './scaleOpinionMulti';
import QuestionOpinionWorkflowActivity from './questionOpinion';
import ReviewQuestionV2WorkflowActivity from './reviewQuestionV2';
import QuestionsMatrixWorkflowActivity from './questionsMatrix';
import ReviewVideo2WorkflowActivity from './reviewVideo2';
import ErrorWorkflowActivity from './error';
import PreferenceOpinionDragWorkflowActivity from './preferenceOpinionDrag';
import PreferenceOpinionDragMultiWorkflowActivity from './preferenceOpinionDragMulti';
import PreferenceSystemOneOpinionWorkflowActivity from './preferenceSystemOneOpinion';
import PreferenceSystemOneOpinionMultiWorkflowActivity from './preferenceSystemOneOpinionMulti';
import RankQuestionOpinionWorkflowActivity from './rankQuestionOpinion';
import RankQuestionOpinionMultiWorkflowActivity from './rankQuestionOpinionMulti';
import RecordOpinionQcWorkflowActivity from './recordOpinionQc';
import WarmupTestQcWorkflowActivity from './warmupTestQc';
import { Dictionary } from 'lib/types';
import ClosedWorkflowActivity from './closed';
import TerminateWorkflowActivity from './terminate';
import RankingOpinionMultiWorkflowActivity from './rankingOpinionMulti';
import InitiativeQuestionsOpinionWorkflowActivity from './initiativeQuestionsOpinion';

enum WORKFLOW_ACTIVITY_TYPES {
  WelcomeV4 = 'WELCOME_V4',
  Details = 'DETAILS',
  RankingOpinion = 'RANKING_OPINION',
  RankingOpinionMulti = 'RANKING_OPINION_MULTI',
  ReviewVideo = 'REVIEW_VIDEO',
  ReviewVideoVimeo = 'REVIEW_VIDEO_VM',
  ReviewVideoVimeo2 = 'REVIEW_VIDEO_VM2',
  ThankYouV3 = 'THANK_YOU_V3',
  Goodbye = 'GOODBYE',
  WarmupTestCt = 'WARMUP_TEST_CT',
  WarmupTestQc = 'WARMUP_TEST_QC',
  RecordOpinionCt = 'RECORD_OPINION_CT',
  RecordOpinionTestCt = 'RECORD_OPINION_TEST_CT',
  RecordOpinionQc = 'RECORD_OPINION_QC',
  RecordOpinionTestQc = 'RECORD_OPINION_TEST_QC',
  AutoSubmit = 'AUTO_SUBMIT',
  Questions = 'QUESTIONS',
  Materials = 'MATERIALS',
  QuestionsOpinion = 'QUESTIONS_OPINION',
  QuestionOpinion = 'QUESTION_OPINION',
  RankingNpsOpinion = 'RANKING_NPS_OPINION',
  ScaleOpinion = 'SCALE_OPINION',
  ScaleOpinionMulti = 'SCALE_OPINION_MULTI',
  ReviewQuestionV2 = 'REVIEW_QUESTION_V2',
  QuestionsMatrix = 'QUESTIONS_MATRIX',
  Error = 'ERROR',
  PreferenceOpinionDrag = 'PREFERENCE_OPINION_DRAG',
  PreferenceOpinionDragMulti = 'PREFERENCE_OPINION_DRAG_MULTI',
  PreferenceSystemOneOpinion = 'PREFERENCE_SYSTEM_ONE_OPINION',
  PreferenceSystemOneOpinionMulti = 'PREFERENCE_SYSTEM_ONE_OPINION_MULTI',
  RankQuestionOpinion = 'RANK_QUESTION_OPINION',
  RankQuestionOpinionMulti = 'RANK_QUESTION_OPINION_MULTI',
  Closed = 'CLOSED',
  Terminate = 'TERMINATE',
  InitiativeQuestionsOpinion = 'INITIATIVE_QUESTIONS_OPINION',
};

const WORKFLOW_ACTIVITY_TYPE_COMPONENTS: Dictionary<React.FunctionComponent<IWorkflowActivityProps>> = {
  [WORKFLOW_ACTIVITY_TYPES.WelcomeV4]: WelcomeV4WorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.Details]: DetailsWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.RankingOpinion]: RankingOpinionWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.RankingOpinionMulti]: RankingOpinionMultiWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.ReviewVideo]: ReviewVideoWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.ReviewVideoVimeo]: ReviewVideoWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.ReviewVideoVimeo2]: ReviewVideo2WorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.ThankYouV3]: ThankYouV3WorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.Goodbye]: GoodbyeWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.WarmupTestCt]: WarmupTestCtWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.WarmupTestQc]: WarmupTestQcWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.RecordOpinionCt]: RecordOpinionCtWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.RecordOpinionTestCt]: RecordOpinionCtWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.RecordOpinionQc]: RecordOpinionQcWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.RecordOpinionTestQc]: RecordOpinionQcWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.AutoSubmit]: AutoSubmitWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.Questions]: QuestionsWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.Materials]: MaterialsWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.QuestionsOpinion]: QuestionsOpinionWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.QuestionOpinion]: QuestionOpinionWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.RankingNpsOpinion]: RankingNpsOpinionWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.ScaleOpinion]: ScaleOpinionWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.ScaleOpinionMulti]: ScaleOpinionMultiWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.ReviewQuestionV2]: ReviewQuestionV2WorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.QuestionsMatrix]: QuestionsMatrixWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.Error]: ErrorWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.PreferenceOpinionDrag]: PreferenceOpinionDragWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.PreferenceOpinionDragMulti]: PreferenceOpinionDragMultiWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.PreferenceSystemOneOpinion]: PreferenceSystemOneOpinionWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.PreferenceSystemOneOpinionMulti]: PreferenceSystemOneOpinionMultiWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.RankQuestionOpinion]: RankQuestionOpinionWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.RankQuestionOpinionMulti]: RankQuestionOpinionMultiWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.Closed]: ClosedWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.Terminate]: TerminateWorkflowActivity,
  [WORKFLOW_ACTIVITY_TYPES.InitiativeQuestionsOpinion]: InitiativeQuestionsOpinionWorkflowActivity,
};

/**
 * Activity types here will only be shown to the user once they call
 * the onReady prop.
 */
const WORKLFOW_ACTIVITY_BLOCKING_TYPES: Dictionary<boolean> = {
  [WORKFLOW_ACTIVITY_TYPES.Details]: true,
  [WORKFLOW_ACTIVITY_TYPES.ReviewVideo]: true,
  [WORKFLOW_ACTIVITY_TYPES.ReviewVideoVimeo]: true,
  [WORKFLOW_ACTIVITY_TYPES.ReviewVideoVimeo2]: true,
  [WORKFLOW_ACTIVITY_TYPES.ThankYouV3]: true,
  [WORKFLOW_ACTIVITY_TYPES.AutoSubmit]: true,
  [WORKFLOW_ACTIVITY_TYPES.ReviewQuestionV2]: true,
};

export {
  WORKFLOW_ACTIVITY_TYPES,
  WORKFLOW_ACTIVITY_TYPE_COMPONENTS,
  WORKLFOW_ACTIVITY_BLOCKING_TYPES,
};
