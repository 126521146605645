import _ from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import IWorkflowActivityProps from '../IWorkflowActivityProps';
import { IWorkflowElement } from 'lib/modules/qualieApi/enities/workflow';
import { Button, Checkbox, Form } from 'antd';
import WorkflowElement from '../../workflowElement';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { WORKFLOW_ELEMENT_TYPES } from '../../workflowElement/types';
import { RuleObject } from 'rc-field-form/lib/interface';
import SplitWorkflowForm from '../../layout/splitWorkflowForm';
import BasicWorkflowForm from '../../layout/basicWorkflowForm';
import { WorkflowActivitySubtitle, WorkflowActivitySubtitleSmall, WorkflowActivityTitle } from '../../content';
import FormActions from '../../layout/formActions';

type Elements = {
  acceptText: IWorkflowElement;
  explainer: IWorkflowElement;
  nextButton: IWorkflowElement;
  projectName: IWorkflowElement;
  qualieLogo: IWorkflowElement;
  qualieWords: IWorkflowElement;
  salutation: IWorkflowElement;
  sortableElements: IWorkflowElement;
  uiTexts: IWorkflowElement;
};

const WelcomeV4WorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const { uiTexts, toNextStep } = useContext(CommencedWorkflowContext);
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const sortableElements = useMemo(() => _.sortBy('position', elements?.sortableElements?.collection), [elements]);
  const acceptTextMarkup = useMemo(() => ({
    __html: elements.acceptText.value || '',
  }), [elements]);

  const rules = useMemo(() => ({
    consent: [
      {
        validator: async (rule: RuleObject, value: boolean) => {
          if (!value) {
            throw new Error(uiTexts?.ACCEPT_TEXT_ERROR?.value);
          }
        },
      },
    ],
  }), [uiTexts]);

  return (
    <SplitWorkflowForm
      className="workflow-activity-welcome-v4"
      focus="empty"
    >
      <SplitWorkflowForm.Filled>
        {sortableElements.map(a => {
          switch (a.workflowElementType) {
            case WORKFLOW_ELEMENT_TYPES.Heading:
              return (
                <WorkflowActivityTitle key={a.name}>{a.value}</WorkflowActivityTitle>
              );
            case WORKFLOW_ELEMENT_TYPES.Label:
              return (
                <React.Fragment key={a.name}>
                  {!!a.label && <WorkflowActivitySubtitle>{a.label}</WorkflowActivitySubtitle>}
                  {!!a.value && <WorkflowActivitySubtitleSmall>{a.value}</WorkflowActivitySubtitleSmall>}
                </React.Fragment>
              );
            default:
              return (<WorkflowElement workflowElement={a} />);
          }
        })}
      </SplitWorkflowForm.Filled>
      <SplitWorkflowForm.Empty>
        <WorkflowElement workflowElement={elements.explainer} />
        <BasicWorkflowForm size="large">
          <Form onFinish={toNextStep}>
            <Form.Item
              name="consent"
              rules={rules.consent}
              valuePropName="checked"
            >
              <Checkbox><span dangerouslySetInnerHTML={acceptTextMarkup} /></Checkbox>
            </Form.Item>
            <FormActions>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  <WorkflowElement workflowElement={elements.nextButton} />
                </Button>
              </Form.Item>
            </FormActions>
          </Form>
        </BasicWorkflowForm>
      </SplitWorkflowForm.Empty >
    </SplitWorkflowForm >
  );
};

export default WelcomeV4WorkflowActivity;
