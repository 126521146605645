import _ from 'lodash/fp';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowElement, IWorkflowFormElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useContext, useEffect, useState } from 'react';
import IWorkflowActivityProps from '../IWorkflowActivityProps';
import { useAppDispatch } from 'app/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { message } from 'antd';
import BasicWorkflowForm from '../../layout/basicWorkflowForm';
import { Dictionary } from 'lib/types';

type Elements = {
  rankingOpinionForm: IWorkflowFormElement;
};

const getInitialValues = (elements: IWorkflowElement[]) => elements.reduce<Dictionary>((acc, curr) => {
  switch (curr.name) {
    case 'opinionActivities':
      acc[curr.name] = curr.collection ? _.values(getInitialValues(curr.collection)) : [];
      break;
    default:
      acc[curr.name] = curr.collection
        ? getInitialValues(curr.collection)
        : curr.value;
  }

  return acc;
}, {});

const AutoSubmitWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const dispatch = useAppDispatch();
  const { flags, toNextStep } = useContext(CommencedWorkflowContext);
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const formElement = elements.rankingOpinionForm;
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted || !formElement || !flags.pp) {
      return;
    }

    (async () => {
      const payload = getInitialValues(formElement.elements);

      try {
        setSubmitted(true);
        await dispatch(workflowsActions.submitWorkflowFormElement({
          actionUrl: formElement.actionURL,
          form: payload,
        })).unwrap();
      } catch (e: any) {
        message.error(e?.message);

        return;
      }
      toNextStep();
    })();
  }, [dispatch, formElement, flags, toNextStep, submitted]);

  return (
    <BasicWorkflowForm size="default" />
  );
};

export default AutoSubmitWorkflowActivity;