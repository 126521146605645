import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/enities/workflow';
import React from 'react';
import CheckboxFormElement from './checkbox';
import CollectionFormElement from './collection';
import EmailFormElement from './email';
import HiddenFormElement from './hidden';
import NumberFormElement from './number';
import RadioFormElement from './radio';
import VideoRatingRadioFormElement from './videoRatingRadio';
import ScaleFormElement from './scale';
import TextFormElement from './text';
import { IWorkflowFormElementProps } from './types';
import NpsRadioFormElement from './npsRadio';
import ScaleSliderFormElement from './scaleSlider';
import ScaleSliderMultiFormElement from './scaleSliderMulti';
import GenericFormElement from './generic';
import TextRatingRadioFormElement from './textRatingRadio';
import ThemometerFormElement from './thermometer';
import MatrixFormElement from './matrix';
import RatingMeasureRadioFormElement from './ratingMeasureRadio';
import PreferenceOpinionFormElement from './preferenceOpinion';
import PreferenceOpinionMultiFormElement from './preferenceOpinionMulti';
import PreferenceSingleOpinionFormElement from './preferenceSingleOpinion';
import { Dictionary } from 'lib/types';
import ThemometerMultiFormElement from './thermometerMulti';
import GenderRadioFormElement from './genderRadio';
import RankingOpinionFormElement from './rankingOpinion';

enum WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES {
  Hidden = 'HIDDEN',
  Text = 'TEXT',
  Number = 'NUMBER',
  Email = 'EMAIL',
  Radio = 'RADIO',
  GenderRadio = 'GENDER_RADIO',
  Collection = 'COLLECTION',
  Generic = 'GENERIC',
  Checkbox = 'CHECKBOX',
  Scale = 'SCALE',
  ScaleSlider = 'SCALE_SLIDER',
  ScaleSliderMulti = 'SCALE_SLIDER_MULTI',
  VideoRatingRadio = 'VIDEO_RATING_RADIO',
  TextRatingRadio = 'TEXT_RATING_RADIO',
  NpsRadio = 'NPS_RADIO',
  ThemometerFormElement = 'THERMOMETER',
  ThermometerMulti = 'THERMOMETER_MULTI',
  Matrix = 'MATRIX',
  RankingOpinion = 'RANKING_OPINION',
  RatingMeasureRadio = 'RATING_MEASURE_RADIO',
  PreferenceOpinion = 'PREFERENCE_OPINION',
  PreferenceOpinionMulti = 'PREFERENCE_OPINION_MULTI',
  PreferenceSingleOpinion = 'PREFERENCE_SINGLE_OPINION',
  ScaleNps = 'SCALE_NPS',
};

const WORKFLOW_ELEMENT_FORM_ELEMENT_TYPE_COMPONENTS: Dictionary<React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>>> = {
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Hidden]: HiddenFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Text]: TextFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Number]: NumberFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Email]: EmailFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Radio]: RadioFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.GenderRadio]: GenderRadioFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Collection]: CollectionFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Generic]: GenericFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Checkbox]: CheckboxFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Scale]: ScaleFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.ScaleSlider]: ScaleSliderFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.ScaleSliderMulti]: ScaleSliderMultiFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.VideoRatingRadio]: VideoRatingRadioFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.TextRatingRadio]: TextRatingRadioFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.NpsRadio]: NpsRadioFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.ThemometerFormElement]: ThemometerFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.ThermometerMulti]: ThemometerMultiFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Matrix]: MatrixFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.RankingOpinion]: RankingOpinionFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.RatingMeasureRadio]: RatingMeasureRadioFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.PreferenceOpinion]: PreferenceOpinionFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.PreferenceOpinionMulti]: PreferenceOpinionMultiFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.PreferenceSingleOpinion]: PreferenceSingleOpinionFormElement,
  [WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.ScaleNps]: NpsRadioFormElement,
};

export {
  WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES,
  WORKFLOW_ELEMENT_FORM_ELEMENT_TYPE_COMPONENTS,
};
