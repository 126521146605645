import { createAsyncThunk } from '@reduxjs/toolkit';
import QualieAPI from 'lib/modules/qualieApi';
import Cookie from 'js-cookie';
import { IPersistedParticipant } from './types';
import workflowsSlice from './slice';
import { ApiResponse, RESPONSE_CODES } from 'lib/modules/qualieApi/types';
import { replace } from 'connected-react-router';
import { HttpStatusCode } from 'lib/constants';
import { Dictionary } from 'lib/types';

const assertApiResponse = createAsyncThunk(
  'workflows/assertApiResponse',
  async (params: { response: ApiResponse<any> }, thunkApi) => {
    switch (params.response.status) {
      case HttpStatusCode.ServiceUnavailable:
        thunkApi.dispatch(replace('/maintenance'));

        throw params.response;
    }
  }
);

const commenceWorkflow = createAsyncThunk(
  'workflows/commenceWorkflow',
  async (params: { workflowId: string, options: { flowName?: string, pp?: string, cookie?: boolean, enableUpload?: boolean, referrer?: string, uid?: string } }, thunkApi) => {
    const options = {
      ...params.options,
      cookie: params.options.cookie != null ? params.options.cookie : true,
      enableUpload: params.options.enableUpload != null ? params.options.enableUpload : false,
    };

    const response = await QualieAPI.Workflow.CommenceWorkflow(params.workflowId, options);
    thunkApi.dispatch(workflowsSlice.actions.setFlags({
      ...options,
      recordSessions: response.body.data && response.body.data[0] ? response.body.data[0].recordSessions : false,
    }));
    await thunkApi.dispatch(assertApiResponse({ response: response })).unwrap();

    return response.body;
  }
);

const submitWorkflowFormElement = createAsyncThunk(
  'workflows/submitWorkflowFormElement',
  async (params: { form: Dictionary, actionUrl: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.SubmitFormElement(params.actionUrl, params.form);
    await thunkApi.dispatch(assertApiResponse({ response: response })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const getPersistedParticipant = createAsyncThunk(
  'workflows/getPersistedParticipant',
  async (params: { workflowId: string }, thunkApi) => {
    const data = Cookie.get(`qualie/workflows/${params.workflowId}/participant`);

    if (!data) {
      throw new Error('No participant found.');
    }

    const payload: IPersistedParticipant = JSON.parse(data);
    thunkApi.dispatch(workflowsSlice.actions.setParticipant({
      participant: payload.participant,
      flags: {
        ...payload.flags,
        cookie: true,
        enableUpload: !!payload.flags.enableUpload,
      },
    }));

    return payload;
  }
);

const setPersistedParticipant = createAsyncThunk(
  'workflows/setPersistedParticipant',
  async (params: { workflowId: string, participant: IPersistedParticipant }) => {
    Cookie.set(`qualie/workflows/${params.workflowId}/participant`, JSON.stringify(params.participant));
  }
);

const createParticipant = createAsyncThunk(
  'workflows/createParticipant',
  async (params: { form: Dictionary, actionUrl: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.CreateParticipant(params.actionUrl, params.form);
    await thunkApi.dispatch(assertApiResponse({ response: response })).unwrap();

    if (![QualieAPI.codes.response.Success, QualieAPI.codes.response.ParticipantExists].includes(response.body.code as RESPONSE_CODES)) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const getNextWorkflowStep = createAsyncThunk(
  '/workflows/getNextWorkflowStep',
  async (params: { workflowId: string, participantId: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.GetNextWorkflowActivity(params.workflowId, params.participantId);

    if (response.body.code !== QualieAPI.codes.response.Success) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const getNextReviewVideo = createAsyncThunk(
  '/workflows/getNextReviewVideo',
  async (params: { nextVideoUrl: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.GetNextReviewVideo(params.nextVideoUrl);
    await thunkApi.dispatch(assertApiResponse({ response: response })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success && response.body.code !== QualieAPI.codes.response.NoContent) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const getNextReviewText = createAsyncThunk(
  '/workflows/getNextReviewText',
  async (params: { nextVideoUrl: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.GetNextReviewText(params.nextVideoUrl);
    await thunkApi.dispatch(assertApiResponse({ response: response })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success && response.body.code !== QualieAPI.codes.response.NoContent) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

const advanceParticipant = createAsyncThunk(
  '/workflows/advanceParticipant',
  async (params: { workflowId: string, participantId: string }, thunkApi) => {
    const response = await QualieAPI.Workflow.AdvanceParticipant(params.workflowId, params.participantId);
    await thunkApi.dispatch(assertApiResponse({ response: response })).unwrap();

    if (response.body.code !== QualieAPI.codes.response.Success) {
      return thunkApi.rejectWithValue(response.body);
    }

    return response.body;
  }
);

export {
  commenceWorkflow,
  submitWorkflowFormElement,
  createParticipant,
  getNextWorkflowStep,
  getNextReviewVideo,
  getNextReviewText,
  advanceParticipant,
  getPersistedParticipant,
  setPersistedParticipant,
};