import { Alert, Button, Form, FormProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch } from 'app/hooks';
import FormActions from 'app/modules/pux/components/layout/formActions';
import { CommencedWorkflowContext, FormElementContext, IFormElementContext } from 'app/modules/pux/contexts';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { workflowsActions } from 'app/modules/workflows';
import QualieAPI from 'lib/modules/qualieApi';
import { IWorkflowElement, IWorkflowFormElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/enities/workflow';
import { Dictionary } from 'lib/types';
import Utils from 'lib/utils';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import FormElement from '../formElement';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '../formElement/types';

const getInitialValues = (elements: IWorkflowElement[]) => elements.reduce<Dictionary>((acc, curr) => {
  switch (curr.name) {
    case 'opinionActivities':
      acc[curr.name] = {
        preferences: _.sortBy(curr.collection || [], 'position').map(element => element.name),
        selected: null,
      };
      break;
    default:
      acc[curr.name] = curr.collection
        ? getInitialValues(curr.collection)
        : curr.value;
      break;
  }

  return acc;
}, {});

const PreferenceOpinionActivityFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement, {initiatives: IWorkflowElement}>> = (props) => {
  const { dependencies, workflowElement, onFinish, onBeforeFinish } = props;
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const [submitted, setSubmitted] = useState(false);
  const intl = useIntl();
  const { activityOptions } = useContext(CommencedWorkflowContext);
  const dispatch = useAppDispatch();
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const [form] = useForm();
  const initialValues = useMemo(() => getInitialValues(elements), [elements]);
  const typeMapper = useCallback((element: IWorkflowFormElementElement) => {
    if (element.name === 'opinionActivities' && element.inputType === WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Collection) {
      return WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.PreferenceSingleOpinion;
    }

    return undefined;
  }, []);
  const formElementContextValue: IFormElementContext = useMemo(() => ({
    form: form,
    typeMapper: typeMapper,
  }), [form, typeMapper]);

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    // This orders the list of initiatives with the selected one appearing as the first element.
    const list = Array.from((_.find(elements, { name: 'opinionActivities' })?.collection || []).map(element => element.name));
    const [removed] = list.splice(_.indexOf(list, values.opinionActivities.selected), 1);
    list.splice(0, 0, removed);

    const parsedValues = {
      ...values,
      opinionActivities: _.map(list, (value, key) => ({
        initiativeHash: value,
        this_one_text: activityOptions?.this_one_text,
        opinionType: 'PREFERENCE',
        type: 'com.qualie.api.dto.PreferenceOpinionActivityDTO',
        preference: key + 1,
      })),
    };

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, []));
          break;
      }
      setError(e?.message || intl.formatMessage({ id: 'workflowElement.form.genericServerError' }));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [activityOptions, elements, intl, workflowElement, dispatch, setFields, onFinish, onBeforeFinish]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <FormElementContext.Provider value={formElementContextValue}>
      <Form
        className="preference-single-opinion-form"
        form={form}
        layout="horizontal"
        onFinish={onFormFinish}
        initialValues={initialValues}
        fields={fields}
      >
        {!!error && (
          <Alert
            type="error"
            message={error}
            showIcon
          />
        )}
        {elements.map(a => (
          <FormElement
            dependencies={dependencies}
            key={a.name}
            workflowElement={a}
          />
        ))}
        <FormActions>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={submitted}
            >{workflowElement.submitButtonText}</Button>
          </Form.Item>
        </FormActions>
      </Form>
    </FormElementContext.Provider>
  );
};

export default PreferenceOpinionActivityFormWorkflowElement;