import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Routes as PuxRoutes } from './modules/pux';
import { history, store } from './store';
import './styles/index.less';
import { IntlProvider } from 'react-intl';
import localeEn from 'locale/en';
import HotjarManager from './modules/pux/components/hotjarManager';

interface IProps { }

const App: React.FunctionComponent<IProps> = (props) => {
  return (
    <Provider store={store}>
      <IntlProvider
        messages={localeEn.messages}
        locale={localeEn.code}
      >
        <HotjarManager />
        <ConnectedRouter history={history}>
          <PuxRoutes />
        </ConnectedRouter>
      </IntlProvider>
    </Provider>
  );
};

export default App;
