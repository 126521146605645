import _ from 'lodash';
import { IWorkflowElement, IWorkflowFormElement, IWorkflowFormElementElement, IWorkflowFormElementElementOption } from 'lib/modules/qualieApi/enities/workflow';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import { Alert, Button, Form, FormProps } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { useIntl } from 'react-intl';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import FormElement from '../formElement';
import { workflowsActions } from 'app/modules/workflows';
import QualieAPI from 'lib/modules/qualieApi';
import FormActions from 'app/modules/pux/components/layout/formActions';
import Utils from 'lib/utils';
import { Dictionary } from 'lib/types';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES } from '../formElement/types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';

type CheckboxElementValues = {
  answers: string[];
  other: string;
}

type RadioElementValues = {
  answer: string;
  other: string;
}

type ScaleElementValues = {
  selected: string;
}

const getInitialValues = (elements: IWorkflowElement[]) => elements.reduce<Dictionary>((acc, curr) => {
  switch (curr.name) {
    default:
      acc[curr.name] = curr.collection
        ? getInitialValues(curr.collection)
        : curr.value;
  }

  return acc;
}, {});

const InitiativeQuestionsOpinionActivityFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>> = (props) => {
  const { workflowElement, onFinish, onBeforeFinish } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { toNextStep } = useContext(CommencedWorkflowContext);
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const elements = useSortedWorkflowElements<IWorkflowFormElementElement>(workflowElement.elements);
  const initialValues = useMemo(() => getInitialValues(elements), [elements]);
  const [submitted, setSubmitted] = useState(false);

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    const opinionActivities = _.keyBy(elements.find(element => element.name === 'opinionActivities')?.collection, 'name');
    const hash = Object.keys(values.opinionActivities)[0];

    const parsedValues = {
      ...values,
      opinionActivities: [{
        type: 'com.qualie.api.dto.QuestionsOpinionActivityDTO',
        opinionType: 'QUESTIONS',
        initiativeHash: hash,
        questionAnswers: (_.find(opinionActivities[hash].collection, { name: 'questionAnswers' })?.collection || []).map(question => {
          let selectedAnswers: Dictionary = {};
          switch (question.inputType) {
            case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Radio:
              const radioValue = values.opinionActivities[hash].questionAnswers[question.name] as RadioElementValues;
              if (radioValue.answer.endsWith('_other')) {
                selectedAnswers[radioValue.answer] = radioValue.other;
              } else {
                const option = question.options?.find((a: IWorkflowFormElementElementOption) => a.key === radioValue.answer);
                selectedAnswers[radioValue.answer] = option ? option.value : radioValue;
              }
              break;
            case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Text:
              selectedAnswers = values.opinionActivities[hash].questionAnswers[question.name];
              break;
            case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Checkbox:
              const checkboxValue = values.opinionActivities[hash].questionAnswers[question.name] as CheckboxElementValues;
              selectedAnswers = checkboxValue.answers.reduce<Dictionary<string>>((acc, v) => {
                if (v.endsWith('_other')) {
                  acc[v] = checkboxValue.other;
                } else {
                  const option = question.options?.find((a: IWorkflowFormElementElementOption) => a.key === v);
                  acc[v] = option ? option.value : v;
                }

                return acc;
              }, {});
              break;
            case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.ThemometerFormElement:
              const thermometerValue = values.opinionActivities[hash].questionAnswers[question.name];
              const thermometerOption = _.find(question.options || [], { value: `${thermometerValue}` });
              selectedAnswers[thermometerOption!.key] = `${thermometerValue}`;
              break;
            case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.Scale:
              const scaleValue = values.opinionActivities[hash].questionAnswers[question.name] as ScaleElementValues;
              const scaleOption = _.find(question.options || [], { key: scaleValue.selected });
              selectedAnswers[scaleValue.selected] = scaleOption!.value;
              break;
            case WORKFLOW_ELEMENT_FORM_ELEMENT_TYPES.ScaleNps:
              const scaleNpsValue = values.opinionActivities[hash].questionAnswers[question.name];
              const scaleNpsOption = _.find(question.options || [], { key: scaleNpsValue });
              selectedAnswers[scaleNpsValue] = scaleNpsOption!.value;
              break;
          }

          return {
            ...question,
            questionHash: question.name,
            selectedAnswers: selectedAnswers,
          };
        }),
      }],
    };

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, []));
          break;
        case QualieAPI.codes.response.Terminated:
          toNextStep();

          return;
      }
      setError(e?.message || intl.formatMessage({ id: 'workflowElement.form.genericServerError' }));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [intl, workflowElement, dispatch, setFields, onFinish, onBeforeFinish, elements, toNextStep]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <Form
      layout="vertical"
      onFinish={onFormFinish}
      initialValues={initialValues}
      fields={fields}
    >
      {!!error && (
        <Alert
          type="error"
          message={error}
          showIcon
        />
      )}
      {elements.map(a => (
        <FormElement
          key={a.name}
          workflowElement={a}
        />
      ))}
      <FormActions>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitted}
          >{workflowElement.submitButtonText}</Button>
        </Form.Item>
      </FormActions>
    </Form>
  );
};

export default InitiativeQuestionsOpinionActivityFormWorkflowElement;
