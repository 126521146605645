import workflowsSlice from './slice';
import workflowsSelectors from './selectors';
import {
  commenceWorkflow,
  submitWorkflowFormElement,
  createParticipant,
  getNextWorkflowStep,
  getNextReviewVideo,
  getNextReviewText,
  advanceParticipant,
  setPersistedParticipant,
  getPersistedParticipant,
} from './thunks';

const workflowsActions = {
  ...workflowsSlice.actions,
  commenceWorkflow,
  submitWorkflowFormElement,
  createParticipant,
  getNextWorkflowStep,
  getNextReviewVideo,
  getNextReviewText,
  advanceParticipant,
  setPersistedParticipant,
  getPersistedParticipant,
};

const workflowsReducer = workflowsSlice.reducer;

export {
  workflowsActions,
  workflowsReducer,
  workflowsSelectors,
};