import { Button, message, Spin } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { IWorkflowCarouselElement, IWorkflowElement, IWorkflowFormElement, IWorkflowInitiativeElement } from 'lib/modules/qualieApi/enities/workflow';
import { Dictionary } from 'lib/types';
import _ from 'lodash/fp';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { WorkflowActivitySubtitle, WorkflowActivityTitle } from '../../content';
import FormActions from '../../layout/formActions';
import SplitWorkflowForm from '../../layout/splitWorkflowForm';
import PrerequisiteError from '../../prerequisiteError';
import WorkflowElement from '../../workflowElement';
import BannerWorkflowElement from '../../workflowElement/types/contentBanner';
import IWorkflowActivityProps from '../IWorkflowActivityProps';

type Elements = {
  title: IWorkflowElement;
  subTitle: IWorkflowElement;
  startRecordingButton: IWorkflowElement;
  explainer: IWorkflowCarouselElement;
  initiatives: IWorkflowInitiativeElement;
  cameraTagOptions: IWorkflowElement;
  techTestOutcomeForm: IWorkflowFormElement;
  cameraTagVideoUploadedForm: IWorkflowFormElement;
};

const WarmupTestCtWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const dispatch = useAppDispatch();
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const { setPrerequisite, checkPrerequisites, toNextStep, uiTexts } = useContext(CommencedWorkflowContext);
  const [explainerValid, setExplainerValid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const showExplainer = !explainerValid && !!elements.explainer;
  const explainers = useMemo(() => _.sortBy('position', elements.explainer?.carouselHtmlElements || []), [elements]);

  const onExplainerValid = useCallback((element: IWorkflowElement, valid: boolean, errorMessage?: string) => {
    setPrerequisite(element.name, {
      reveal: false,
      valid: valid,
      error: errorMessage || uiTexts?.WATCH_EXPLAINER_VIDEO.value,
    });
  }, [setPrerequisite, uiTexts]);

  const onExplainerFinish = useCallback(() => {
    if (checkPrerequisites()) {
      setExplainerValid(true);
    }
  }, [checkPrerequisites]);

  const onRecordingFinish = useCallback(async (element: IWorkflowElement, payload: any) => {
    const techTestPayload = elements.techTestOutcomeForm.elements.reduce<Dictionary>((acc, curr) => {
      switch (curr.name) {
        case 'techTestOutcome':
          acc[curr.name] = 'PASSED';
          break;
        default:
          acc[curr.name] = curr.value;
          break;
      }

      return acc;
    }, {});

    const uploadedPayload = elements.cameraTagVideoUploadedForm.elements.reduce<Dictionary>((acc, curr) => {
      switch (curr.name) {
        case 'url':
          acc[curr.name] = payload.url;
          break;
        case 'uuid':
          acc[curr.name] = payload.uuid;
          break;
        default:
          acc[curr.name] = curr.value;
          break;
      }

      return acc;
    }, {});

    setProcessing(true);
    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        form: uploadedPayload,
        actionUrl: elements.cameraTagVideoUploadedForm.actionURL,
      })).unwrap();
    } catch (e: any) {
      setProcessing(false);
      message.error(e.message, 3);

      return;
    }
    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        form: techTestPayload,
        actionUrl: elements.techTestOutcomeForm.actionURL,
      })).unwrap();
    } catch (e: any) {
      setProcessing(false);
      message.error(e.message, 3);

      return;
    }
    setProcessing(false);

    toNextStep();
  }, [elements, toNextStep, dispatch]);

  return (
    <SplitWorkflowForm focus="empty">
      <SplitWorkflowForm.Filled>
        <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
        <WorkflowActivitySubtitle>{elements.subTitle.value}</WorkflowActivitySubtitle>
      </SplitWorkflowForm.Filled>
      <SplitWorkflowForm.Empty header={!showExplainer && !!elements.explainer && <BannerWorkflowElement explainers={explainers} />}>
        {showExplainer && (
          <React.Fragment>
            <WorkflowElement
              workflowElement={elements.explainer}
              onValid={onExplainerValid}
            />
            <PrerequisiteError target="explainer" />
            <FormActions>
              <Button
                type="primary"
                onClick={onExplainerFinish}
              >{elements.startRecordingButton.value}</Button>
            </FormActions>
          </React.Fragment>
        )}
        {!showExplainer && (
          <React.Fragment>
            {!processing && (
              <div className="workflow-warmup-test-recording">
                <WorkflowElement
                  workflowElement={elements.cameraTagOptions}
                  onFinish={onRecordingFinish}
                />
              </div>
            )}
            {processing && <div className="workflow-warmup-test-processing"><Spin size="large" /></div>}
          </React.Fragment>
        )}
      </SplitWorkflowForm.Empty>
    </SplitWorkflowForm>
  );
};

export default WarmupTestCtWorkflowActivity;