import { Button, Spin } from 'antd';
import { useAppDispatch, useMountEffect } from 'app/hooks';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { IReviewVideo, IWorkflowCarouselElement, IWorkflowElement, IWorkflowFormElement, IWorkflowInitiativeElement } from 'lib/modules/qualieApi/enities/workflow';
import Utils from 'lib/utils';
import _ from 'lodash/fp';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { WorkflowActivitySubtitle, WorkflowActivityTitle } from '../../content';
import BasicWorkflowForm from '../../layout/basicWorkflowForm';
import { IBasicWorkflowFormRef } from '../../layout/basicWorkflowForm/basicWorkflowForm';
import FormActions from '../../layout/formActions';
import SplitWorkflowForm from '../../layout/splitWorkflowForm';
import PrerequisiteError from '../../prerequisiteError';
import WorkflowElement from '../../workflowElement';
import { WORKFLOW_ELEMENT_TYPES } from '../../workflowElement/types';
import BannerWorkflowElement from '../../workflowElement/types/contentBanner';
import IWorkflowActivityProps from '../IWorkflowActivityProps';

type Elements = {
  title: IWorkflowElement;
  subTitle: IWorkflowElement;
  nextVideoURL: IWorkflowElement;
  initiatives: IWorkflowInitiativeElement;
  uiTexts: IWorkflowElement;
  reviewOthersButton: IWorkflowElement;
  showPlayerControls: IWorkflowElement;
  reviewOpinionForm: IWorkflowFormElement;
  explainer: IWorkflowCarouselElement;
  explainerButtonText: IWorkflowElement;
};

const ReviewVideoWorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const dispatch = useAppDispatch();
  const { advance, setPrerequisite, uiTexts, checkPrerequisites, onReady } = useContext(CommencedWorkflowContext);
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const [explainerValid, setExplainerValid] = useState(!elements.explainer);
  const [reviewVideo, setReviewVideo] = useState<IReviewVideo>();
  const formContainerRef = useRef<IBasicWorkflowFormRef | null>(null);
  const showExplainer = !explainerValid && !!elements.explainer;
  const videoWorkflowElement = useMemo(() => (
    reviewVideo?.opinionActivity?.video
      ? {
        name: reviewVideo.opinionActivity.video.name,
        visible: true,
        workflowElementType: WORKFLOW_ELEMENT_TYPES.ExternalVideo,
        provider: reviewVideo.opinionActivity.video.videoProvider,
        width: reviewVideo.opinionActivity.video.width,
        height: reviewVideo.opinionActivity.video.height,
        thumbnail: reviewVideo.opinionActivity.video.thumbnail,
        videoId: reviewVideo.opinionActivity.video.key,
        position: '1',
      }
      : null
  ), [reviewVideo]);

  const explainers = useMemo(() => _.sortBy('position', elements.explainer?.carouselHtmlElements || []), [elements]);
  const stimuli = useMemo(() => {
    const initiative = (elements.initiatives?.collection || [])[0] as any;

    return _.sortBy('position', initiative?.stimuli || []);
  }, [elements]);

  const reviewOpinionForm: IWorkflowFormElement = useMemo(() => ({
    ...elements.reviewOpinionForm,
    elements: elements.reviewOpinionForm.elements.map(a => {
      switch (a.name) {
        /**
         * Need to inject the current opinion hash into the activity so the form
         * uses that value in the input for submission.
         */
        case 'opinionHash':
          return {
            ...a,
            value: reviewVideo?.opinionActivity.hash,
          };
        default:
          return a;
      }
    }),
  }), [elements.reviewOpinionForm, reviewVideo]);

  const onExplainerValid = useCallback((element: IWorkflowElement, valid: boolean, errorMessage?: string) => {
    setPrerequisite(element.name, {
      reveal: false,
      valid: valid,
      error: errorMessage || uiTexts?.VIEW_ALL_CONTENT.value,
    });
  }, [setPrerequisite, uiTexts]);

  const onExplainerFinish = useCallback(() => {
    if (checkPrerequisites()) {
      setExplainerValid(true);
    }
  }, [checkPrerequisites]);

  const onNextVideo = useCallback(async () => {
    if (videoWorkflowElement) {
      setPrerequisite(videoWorkflowElement.name, null);
      setReviewVideo(undefined);
    }
    if (elements?.nextVideoURL?.value) {
      const result = await dispatch(workflowsActions.getNextReviewVideo({ nextVideoUrl: elements.nextVideoURL.value })).unwrap();
      if (result.data == null) {
        advance();
      } else {
        setReviewVideo(result.data);
        Utils.scrollToTop();
        onReady();
      }
    }
  }, [elements?.nextVideoURL?.value, dispatch, advance, setPrerequisite, videoWorkflowElement, onReady]);

  const onVideoValid = useCallback((element: IWorkflowElement, valid: boolean) => {
    setPrerequisite(element.name, valid ? null : {
      valid: valid,
      error: (!valid && uiTexts?.WATCH_VIDEO?.value) || null,
      reveal: false,
    });
    formContainerRef.current?.focus();
  }, [setPrerequisite, uiTexts]);

  useMountEffect(() => {
    onNextVideo();
  });

  return (
    <SplitWorkflowForm
      className="workflow-activity-review-video"
      focus="empty"
    >
      <SplitWorkflowForm.Filled>
        <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
        <WorkflowActivitySubtitle>{elements.subTitle.value}</WorkflowActivitySubtitle>
      </SplitWorkflowForm.Filled>
      <SplitWorkflowForm.Empty
        header={!showExplainer && !!elements.explainer && !!reviewVideo && (
          <BannerWorkflowElement
            explainers={explainers}
            stimuli={stimuli}
          />
        )}
      >
        {!reviewVideo && <Spin size="large" />}
        {showExplainer && (
          <React.Fragment>
            <WorkflowElement
              workflowElement={elements.explainer}
              onValid={onExplainerValid}
            />
            <PrerequisiteError target="explainer" />
            <FormActions>
              <Button
                type="primary"
                onClick={onExplainerFinish}
              >{elements.explainerButtonText.value}</Button>
            </FormActions>
          </React.Fragment>
        )}
        {!showExplainer && videoWorkflowElement && (
          <React.Fragment>
            <WorkflowElement
              workflowElement={videoWorkflowElement}
              onValid={onVideoValid}
            />
            <BasicWorkflowForm
              size="large"
              ref={formContainerRef}
            >
              <PrerequisiteError target={videoWorkflowElement.name} />
              <WorkflowElement
                workflowElement={reviewOpinionForm}
                onFinish={onNextVideo}
                onBeforeFinish={checkPrerequisites}
              />
            </BasicWorkflowForm>
          </React.Fragment>
        )}
      </SplitWorkflowForm.Empty>
    </SplitWorkflowForm >
  );
};

export default ReviewVideoWorkflowActivity;
