import { useAppDispatch, useAppSelector, useMountEffect, useQueryParams } from 'app/hooks';
import { RouteComponentProps } from 'react-router';
import appRoutes from 'app/routes';
import { workflowsActions } from 'app/modules/workflows';
import React, { useEffect } from 'react';
import { workflowsSelectors } from 'app/modules/workflows/store';
import _ from 'lodash/fp';
import { push } from 'connected-react-router';

interface IProps extends RouteComponentProps<typeof appRoutes['routes']['workflowLanding']['params']> { }

const WorkflowLandingRoute: React.FunctionComponent<IProps> = (props) => {
  const { match } = props;
  const queryParams = useQueryParams<typeof appRoutes['routes']['workflowLanding']['query']>();
  const dispatch = useAppDispatch();
  const commencedWorkflow = useAppSelector(workflowsSelectors.getCommencedWorkflow);

  useMountEffect(() => {
    dispatch(workflowsActions.commenceWorkflow({
      workflowId: match.params!.workflowId,
      options: {
        cookie: queryParams?.cookie,
        flowName: queryParams?.flowName || 'OFF_CAM',
        pp: queryParams?.pp || 'QLE',
        enableUpload: queryParams?.enableUpload,
        referrer: queryParams?.ref,
        uid: queryParams?.uid,
      },
    }));
  });

  useEffect(() => {
    const first = _.sortBy('position', commencedWorkflow?.data)?.shift();
    if (first) {
      dispatch(push(appRoutes.route('workflowElementStep', {
        workflowId: match.params!.workflowId,
        position: (String)(first.position),
        workflowActivityType: first.workflowActivityType,
      }, {})));
    }
  }, [match.params, queryParams, commencedWorkflow, dispatch]);

  return (
    <div></div>
  );
};

export default WorkflowLandingRoute;
