import { createBrowserHistory, LocationState } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { appReducer } from './slice';
import { puxReducer } from '../modules/pux';
import { workflowsReducer } from '../modules/workflows';
import Utils from 'lib/utils';

const history = createBrowserHistory();
const store = configureStore({
  reducer: {
    appReducer,
    puxReducer,
    workflowsReducer,
    router: connectRouter<LocationState>(history),
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history)),
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

history.listen((location) => {
  Utils.scrollToTop();
});

export type {
  RootState,
  AppDispatch,
};

export {
  store,
  history,
};