import _ from 'lodash';
import { AudioOutlined, CaretRightOutlined, CloseOutlined, DislikeFilled, LikeFilled, SettingFilled, VideoCameraOutlined, WarningFilled } from '@ant-design/icons';
import { useVideoRecorder, VideoRecorderStatus } from 'app/hooks/video';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import QualieAPI from 'lib/modules/qualieApi';
import { IWorkflowQualieCameraElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import IWorkflowElementProps from '../../IWorkflowElementProps';
import { Button, Divider, Form, notification, Progress, Select } from 'antd';
import cx from 'classnames';
import { useCountdown } from 'app/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { QuestionLabel } from '../../../content';
import RecordProgress from './recordProgress';
import { useSilenceMonitor } from 'app/hooks/audio';
import SoundWaveform from './soundWaveform';
import Guidelines from './guidelines';

const COUNTDOWN = 3;
const DEFAULT_MAX_RECORD_TIME = 120;
const DEFAULT_MIN_RECORD_TIME = (Number)(process.env.REACT_APP_MIN_RECORD_TIME);

interface IRecordingActionButtonProps {
  onClick: () => void;
}

const RecordButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action record animate__animated animate__zoomInUp"
  />
);

const StopButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action stop animate__animated animate__zoomInUp"
  />
);

const ResetButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <Button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action reset animate__animated animate__zoomInUp"
    icon={<DislikeFilled />}
    type="primary"
  >
    <FormattedMessage id="workflowElement.qualieCamera.action.reset.label" />
  </Button>
);

const UploadButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <Button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action upload animate__animated animate__zoomInUp"
    icon={<LikeFilled />}
    type="primary"
  >
    <FormattedMessage id="workflowElement.qualieCamera.action.upload.label" />
  </Button>
);

const PreviewButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <Button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action upload animate__animated animate__zoomInUp"
    icon={<CaretRightOutlined />}
    type="primary"
  >
    <FormattedMessage id="workflowElement.qualieCamera.action.preview.label" />
  </Button>
);

const DevicesButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action devices animate__animated animate__zoomInDown"
  >
    <SettingFilled />
  </button>
);

const CancelButton: React.FunctionComponent<IRecordingActionButtonProps> = ({
  onClick,
}) => (
  <button
    onClick={onClick}
    className="qualie-camera-workflow-element-recording-action cancel animate__animated animate__zoomInDown"
  >
    <CloseOutlined />
  </button>
);

const RemainingTime: React.FunctionComponent<{ value: number }> = ({
  value,
}) => {
  const label = useMemo(() => {
    const asSeconds = value / 1000;
    const minutes = Math.floor(asSeconds / 60);
    const seconds = Math.floor(asSeconds % 60);

    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, [value]);

  return (<div className="qualie-camera-worfklow-element-remaining-time animate__animated animate__zoomInUp">{label}</div>);
};

enum QualieCameraStatus {
  Record = 'RECORD',
  Uploading = 'UPLOADING',
  Preview = 'PREVIEW',
  DeviceConfiguration = 'DEVICE_CONFIGURATION',
};

type DeviceConfiguration = {
  video: MediaDeviceInfo[];
  audio: MediaDeviceInfo[];
  currentAudioDeviceId: string;
  currentVideoDeviceId: string;
};

const QualieCameraWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowQualieCameraElement>> = ({
  workflowElement,
  onFinish,
}) => {
  const intl = useIntl();
  const [
    onStartRecording,
    onStopRecording,
    onReset,
    onGetDevices,
    onSetDevices,
    videoRecorderStatus,
    duration,
    mediaStream,
    recordingBlob,
  ] = useVideoRecorder();
  const [status, setStatus] = useState<QualieCameraStatus>(QualieCameraStatus.Record);
  const [nextStatus, setNextStatus] = useState<QualieCameraStatus>();
  const livePreviewRef = useRef<HTMLVideoElement | null>();
  const previewRef = useRef<HTMLVideoElement | null>();
  const [countdown, setCountdown] = useCountdown();
  const { participant, workflowId } = useContext(CommencedWorkflowContext);
  const [revealProgress, setRevealProgress] = useState(false);
  const [previewProgress, setPreviewProgress] = useState(0);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [recordingDataUrl, setRecordingDataUrl] = useState<string>();
  const [silenceDetected] = useSilenceMonitor(videoRecorderStatus === VideoRecorderStatus.Recording ? mediaStream : null);
  const [devices, setDevices] = useState<DeviceConfiguration>();
  const [flipVideo] = useState(true);
  const minRecordTime = DEFAULT_MIN_RECORD_TIME * 1000;
  const maxRecordTime = useMemo(() => {
    let value = Number.parseInt(workflowElement.recordLength);
    if (Number.isNaN(value) || value == null) {
      value = DEFAULT_MAX_RECORD_TIME;
    }

    return value * 1000;
  }, [workflowElement.recordLength]);
  const className = useMemo(() => cx({
    'qualie-camera-workflow-element': true,
    'active': countdown != null || videoRecorderStatus === VideoRecorderStatus.Recording,
    'uploading': status === QualieCameraStatus.Uploading,
    'preview': status === QualieCameraStatus.Preview,
    'flip-video': flipVideo,
  }), [status, countdown, videoRecorderStatus, flipVideo]);

  const onTryStopRecording = useCallback(() => {
    if (duration < minRecordTime) {
      setRevealProgress(true);
    } else {
      onStopRecording();
    }
  }, [duration, minRecordTime, onStopRecording]);

  const onStartCountdown = useCallback(() => {
    setCountdown(COUNTDOWN);
  }, [setCountdown]);

  const onStartUpload = useCallback(async () => {
    if (!workflowId || !participant?.hash || !recordingBlob) {
      return;
    }

    setNextStatus(QualieCameraStatus.Uploading);
  }, [participant?.hash, recordingBlob, workflowId]);

  const onUpload = useCallback(async () => {
    if (!workflowId || !participant?.hash || !recordingBlob) {
      return;
    }

    setUploadProgress(0);
    const result = await QualieAPI.Workflow.UploadParticipantVideo(workflowId, participant.hash, recordingBlob, (progress, total) => {
      setUploadProgress(Math.round(_.clamp((progress || 0) / (total || 1), 0, 1) * 100));
    });
    setUploadProgress(100);

    onFinish?.(workflowElement, result.body.data);
  }, [onFinish, participant?.hash, recordingBlob, workflowElement, workflowId]);

  const onPlayPreview = useCallback(() => {
    if (previewRef.current) {
      previewRef.current.pause();
      previewRef.current.currentTime = 0;
      previewRef.current.play();
    }
  }, []);

  const onStartPreview = useCallback(() => {
    setNextStatus(QualieCameraStatus.Preview);
    onPlayPreview();
  }, [onPlayPreview]);

  const onEndPreview = useCallback(() => {
    try {
      if (previewRef.current) {
        previewRef.current.pause();
        previewRef.current.currentTime = 0;
      }
    } catch (e) {
      console.warn(e);
    };
    setNextStatus(QualieCameraStatus.Record);
  }, []);

  const onDeviceConfiguration = useCallback(async () => {
    const devices = await onGetDevices();
    const audioTrack = mediaStream?.getAudioTracks()[0];
    const videoTrack = mediaStream?.getVideoTracks()[0];

    setDevices({
      video: devices.filter(a => a.kind === 'videoinput'),
      audio: devices.filter(a => a.kind === 'audioinput'),
      currentAudioDeviceId: audioTrack?.getCapabilities().deviceId as string || 'default',
      currentVideoDeviceId: videoTrack?.getCapabilities().deviceId as string || 'default',
    });
    setNextStatus(QualieCameraStatus.DeviceConfiguration);
  }, [onGetDevices, mediaStream]);

  const onDeviceConfigurationClose = useCallback(() => {
    setNextStatus(QualieCameraStatus.Record);
  }, []);

  const onChangeVideo = useCallback((deviceId: string) => {
    setDevices(value => ({
      ...value,
      currentVideoDeviceId: deviceId,
    }) as DeviceConfiguration);
    onSetDevices({ videoDeviceId: deviceId });
  }, [onSetDevices]);

  const onChangeAudio = useCallback((deviceId: string) => {
    setDevices(value => ({
      ...value,
      currentAudioDeviceId: deviceId,
    }) as DeviceConfiguration);
    onSetDevices({ audioDeviceId: deviceId });
  }, [onSetDevices]);

  useEffect(() => {
    if (mediaStream != null) {
      if (livePreviewRef.current) {
        livePreviewRef.current.srcObject = mediaStream;
      }
    }
  }, [mediaStream]);

  useEffect(() => {
    if (countdown === 0 && videoRecorderStatus === VideoRecorderStatus.Ready) {
      onStartRecording();
    }
  }, [countdown, videoRecorderStatus, onStartRecording]);

  useEffect(() => {
    if (videoRecorderStatus === VideoRecorderStatus.Recording && duration >= maxRecordTime) {
      onStopRecording();
    }
  }, [videoRecorderStatus, duration, maxRecordTime, onStopRecording]);

  useEffect(() => {
    setRecordingDataUrl(recordingBlob != null ? URL.createObjectURL(recordingBlob) : undefined);
  }, [recordingBlob]);

  useEffect(() => () => {
    if (recordingDataUrl != null) {
      URL.revokeObjectURL(recordingDataUrl);
      setRecordingDataUrl(undefined);
    };
  }, [recordingDataUrl]);

  useEffect(() => {
    if (nextStatus != null && status !== nextStatus) {
      setStatus(nextStatus);
      switch (nextStatus) {
        case QualieCameraStatus.Uploading:
          onUpload();
          break;
        case QualieCameraStatus.Preview:
          setPreviewProgress(0);
          break;
        case QualieCameraStatus.DeviceConfiguration:
          onReset();
          break;
      }
    }
  }, [
    status,
    nextStatus,
    onUpload,
    onStopRecording,
    onReset,
  ]);

  useEffect(() => {
    if (status !== QualieCameraStatus.Preview) {
      return;
    }

    const length = Math.floor(duration / 1000);
    const handle = setInterval(() => {
      setPreviewProgress(Math.ceil(((previewRef.current?.currentTime || 0) / length) * 100));
    }, 1000 / 24);

    return () => {
      clearInterval(handle);
    };
  }, [status, duration]);

  useEffect(() => {
    if (silenceDetected) {
      const message = intl.formatMessage({ id: 'workflowElement.qualieCamera.silenceNotification.content' });
      const cta = intl.formatMessage({ id: 'workflowElement.qualieCamera.silenceNotification.cta' });

      notification.open({
        key: 'workflowElement.qualieCamera.silenceNotification',
        message: (
          <React.Fragment>
            <div className="qualie-camera-silence-notification-content">{message}</div>
            <Button
              type="primary"
              onClick={() => {
                notification.close('workflowElement.qualieCamera.silenceNotification');
                onStopRecording();
                onDeviceConfiguration();
              }}
            >{cta}</Button>
          </React.Fragment>
        ),
        placement: 'bottom',
        duration: null,
        className: 'qualie-camera-silence-notification',
      });
    } else {
      notification.close('workflowElement.qualieCamera.silenceNotification');
    }
  }, [silenceDetected, intl, onStopRecording, onDeviceConfiguration]);

  return (
    <div className={className}>
      <div className="qualie-camera-workflow-element-container">
        <div className="qualie-camera-workflow-element-live-preview">
          <video
            ref={a => livePreviewRef.current = a}
            autoPlay
            muted
            playsInline
          />
        </div>
        <div className="qualie-camera-workflow-element-recording-preview">
          <video
            ref={a => previewRef.current = a}
            src={recordingDataUrl}
            playsInline
            onEnded={onEndPreview}
          />
          <Progress
            percent={previewProgress}
            showInfo={false}
            status="normal"
            size="small"
          />
        </div>
        <div className="qualie-camera-workflow-element-overlay">
          {status === QualieCameraStatus.Record && (
            <React.Fragment>
              {videoRecorderStatus === VideoRecorderStatus.NoPermissionGranted && (
                <div className="qualie-camera-workflow-element-overlay-error">
                  <div className="qualie-camera-workflow-element-overlay-error-content">
                    <WarningFilled />
                    <p>
                      <FormattedMessage
                        id="workflowElement.qualieCamera.error.noPermissions"
                        values={{ br: <br /> }}
                      />
                    </p>
                  </div>
                </div>
              )}
              {videoRecorderStatus === VideoRecorderStatus.Ready && (
                <React.Fragment>
                  <Guidelines />
                  {countdown == null && (
                    <React.Fragment>
                      <div className="qualie-camera-workflow-element-overlay-ready-toolbar">
                        <DevicesButton onClick={onDeviceConfiguration} />
                      </div>
                      <div className="qualie-camera-workflow-element-overlay-ready">
                        <RecordButton onClick={onStartCountdown} />
                      </div>
                    </React.Fragment>
                  )}
                  {countdown != null && (<div className="qualie-camera-workflow-element-overlay-countdown">{countdown}</div>)}
                </React.Fragment>
              )}
              {videoRecorderStatus === VideoRecorderStatus.Recording && (
                <React.Fragment>
                  <div className="qualie-camera-workflow-element-overlay-recording">
                    <StopButton onClick={onTryStopRecording} />
                    <RemainingTime value={maxRecordTime - duration} />
                    {revealProgress && (
                      <RecordProgress
                        min={minRecordTime}
                        max={maxRecordTime}
                        value={duration}
                      />
                    )}
                  </div>
                  <div className="qualie-camera-workflow-element-overlay-recording-blink" />
                </React.Fragment>
              )}
              {videoRecorderStatus === VideoRecorderStatus.RecordingComplete && (
                <div className="qualie-camera-workflow-element-overlay-recording-complete">
                  <UploadButton onClick={onStartUpload} />
                  <ResetButton onClick={onReset} />
                  <PreviewButton onClick={onStartPreview} />
                </div>
              )}
            </React.Fragment>
          )}
          {status === QualieCameraStatus.Uploading && (
            <React.Fragment>
              {uploadProgress !== null && (
                <div className="qualie-camera-workflow-element-overlay-upload-progress animate__animated animate__fadeIn">
                  <Progress
                    className="animate__animated animate__zoomInUp"
                    type="circle"
                    percent={uploadProgress}
                  />
                </div>
              )}
            </React.Fragment>
          )}
          {status === QualieCameraStatus.DeviceConfiguration && (
            <React.Fragment>
              <div className="qualie-camera-workflow-element-device-configuration">
                <Form layout="vertical">
                  <h3>
                    <FormattedMessage id="workflowElement.qualieCamera.devices.configuration.heading" />
                  </h3>
                  <Divider />
                  <Form.Item label={<QuestionLabel><span><VideoCameraOutlined /> <FormattedMessage id="workflowElement.qualieCamera.devices.video.label" /></span></QuestionLabel>}>
                    <Select
                      value={devices?.currentVideoDeviceId}
                      onChange={onChangeVideo}
                    >
                      {devices?.video.map(device => (
                        <Select.Option
                          key={device.deviceId}
                          value={device.deviceId}
                        >{device.label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={<QuestionLabel><span><AudioOutlined /> <FormattedMessage id="workflowElement.qualieCamera.devices.audio.label" /></span></QuestionLabel>}
                    extra={(
                      <SoundWaveform
                        mediaStream={mediaStream}
                        direction="horizontal"
                      />
                    )}
                  >
                    <Select
                      value={devices?.currentAudioDeviceId}
                      onChange={onChangeAudio}
                    >
                      {devices?.audio.map(device => (
                        <Select.Option
                          key={device.deviceId}
                          value={device.deviceId}
                        >{device.label}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={onDeviceConfigurationClose}
                  >
                    <FormattedMessage id="workflowElement.qualieCamera.devices.submit.label" />
                  </Button>
                </Form>
              </div>
            </React.Fragment>
          )}
          {status === QualieCameraStatus.Preview && (
            <div className="qualie-camera-workflow-element-overlay-preview-toolbar">
              <CancelButton onClick={onEndPreview} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QualieCameraWorkflowElement;
