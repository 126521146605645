import { Alert, Button, Form, FormProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch } from 'app/hooks';
import FormActions from 'app/modules/pux/components/layout/formActions';
import { useSortedWorkflowElements } from 'app/modules/pux/hooks';
import { workflowsActions } from 'app/modules/workflows';
import QualieAPI from 'lib/modules/qualieApi';
import { IWorkflowElement, IWorkflowFormElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/enities/workflow';
import { Dictionary } from 'lib/types';
import Utils from 'lib/utils';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import FormElement from '../formElement';

const getInitialValues = (elements: IWorkflowElement[]) => elements.reduce<Dictionary>((acc, curr) => {
  switch (curr.name) {
    case 'opinionActivities':
      acc[curr.name] = _.sortBy(curr.collection || [], 'position').map(element => element.name);
      break;
    default:
      acc[curr.name] = curr.collection
        ? getInitialValues(curr.collection)
        : curr.value;
      break;
  }

  return acc;
}, {});

const RankQuestionOpinionMultiFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement>> = (props) => {
  const { dependencies, workflowElement, onFinish, onBeforeFinish } = props;
  const [error, setError] = useState<string | null>();
  const [fields, setFields] = useState<FormProps['fields']>();
  const [submitted, setSubmitted] = useState(false);
  const [groupedInitiatives] = useState(_.groupBy((dependencies?.initiatives?.collection || []), 'name'));
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const elements = useSortedWorkflowElements(workflowElement.elements);
  const [form] = useForm();
  const initialValues = useMemo(() => getInitialValues(elements), [elements]);

  const onFormFinish = useCallback(async (values) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    const opinionActivities = _.keyBy(elements.find(element => element.name === 'opinionActivities')?.collection, 'name');
    const hash = Object.keys(values.opinionActivities)[0];
    const opinion: IWorkflowFormElementElement = opinionActivities[hash];
    const question = _.find(opinion.collection, { name: 'question' });
    const rank: any = _.isObject(values.opinionActivities[hash]) ? (values.opinionActivities[hash] as any).rank.answer : values.opinionActivities[hash];
    const option = _.find(_.find(opinion.collection, { name: 'rank' })?.options, { key: rank });

    let results: any[] = [];
    // This code maps the provided form structure to what the API expects.
    _.forEach(groupedInitiatives, (groupedInitiative: IWorkflowElement[], groupKey: string) => {
      _.forEach(groupedInitiative, (val: any) => {
        results.push({
          initiativeHash: val.hash,
          question: question?.value,
          answer: option?.value,
          participantHash: values.participantHash,
          type: 'com.qualie.api.dto.RankQuestionOpinionActivityDTO',
          opinionType: 'RANK_QUESTION',
          rank: rank,
        });
      });
    });

    const parsedValues = {
      ...values,
      opinionActivities: results,
    };

    setSubmitted(true);
    setError(null);
    setFields(fields => fields?.map(a => ({
      name: a.name,
      errors: [],
    })));

    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: parsedValues,
      })).unwrap();
    } catch (e: any) {
      setSubmitted(false);
      switch (e?.code) {
        case QualieAPI.codes.response.InvalidForm:
          setFields(Utils.apiToFormErrors(e?.data || {}, []));
          break;
      }
      setError(e?.message || intl.formatMessage({ id: 'workflowElement.form.genericServerError' }));

      return;
    }

    if (onFinish) {
      onFinish(workflowElement);
    }
  }, [elements, groupedInitiatives, intl, workflowElement, dispatch, setFields, onFinish, onBeforeFinish]);

  useEffect(() => {
    setSubmitted(false);
  }, [initialValues]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFormFinish}
      initialValues={initialValues}
      fields={fields}
    >
      {!!error && (
        <Alert
          type="error"
          message={error}
          showIcon
        />
      )}
      {elements.map(a => (
        <FormElement
          dependencies={dependencies}
          key={a.name}
          workflowElement={a}
        />
      ))}
      <FormActions>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitted}
          >{workflowElement.submitButtonText}</Button>
        </Form.Item>
      </FormActions>
    </Form>
  );
};

export default RankQuestionOpinionMultiFormWorkflowElement;