import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useContext, useMemo } from 'react';
import { IWorkflowFormElementProps } from './types';
import { Form, Radio } from 'antd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import cx from 'classnames';
import { useIsMobile } from 'app/hooks';
import { QuestionLabel } from 'app/modules/pux/components/content';
import { StarFilled } from '@ant-design/icons';
import ErrorMessage from '../../../errorMessage';

const RadioLabel: React.FunctionComponent<{index: number, option: any, selectedIndex: number, starRating: boolean}> = ({
  index,
  option,
  selectedIndex,
  starRating,
}) => {
  const isMobile = useIsMobile();

  let label: any = isMobile ? option.label : index + 1;
  if (starRating) {
    label = <StarFilled className={index <= selectedIndex ? 'star-filled' : 'star-empty'} />;
  }

  return (
    <span>{label}</span>
  );
};

const OVERSIZE_THRESHOLD = 6;
const ScaleFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const options = useMemo(() => _.sortBy('position', workflowElement.options), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const hasRatingTag = workflowElement.tag && workflowElement.tag === 'rating';
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const className = useMemo(() => cx({
    'scale-form-element': true,
    'oversized': options.length > OVERSIZE_THRESHOLD,
    'star-rating': hasRatingTag,
  }), [options, hasRatingTag]);

  const optionClassName = useMemo(() => cx({
    'scale-form-element-option': true,
    'star-rating-star': hasRatingTag,
  }), [hasRatingTag]);

  return (
    <Form.Item
      className={className}
      label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
      shouldUpdate={true}
    >
      {form => {
        const value = form.getFieldValue(name);
        const selectedIndex = _.findIndex({ key: value?.selected }, options);

        return (
          <Form.Item
            name={[...name, 'selected']}
            rules={[
              {
                required: workflowElement.required,
                message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
              },
            ]}
          >
            <Radio.Group>
              {options.map((option, index) => (
                <Radio.Button
                  className={optionClassName}
                  key={option.value}
                  value={option.key}
                >
                  <RadioLabel
                    index={index}
                    option={option}
                    selectedIndex={selectedIndex}
                    starRating={hasRatingTag || false}
                  />
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default ScaleFormElement;
