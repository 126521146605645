import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import { QuestionLabel } from 'app/modules/pux/components/content';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { RegexPattern } from 'lib/constants';
import { IWorkflowFormElementElement, IWorkflowFormElementElementOption, WorkflowFormElementElementOptionPattern } from 'lib/modules/qualieApi/enities/workflow';
import React, { useContext, useMemo } from 'react';
import ErrorMessage from '../../../errorMessage';
import { IWorkflowFormElementProps } from './types';

const ElementOption: React.FunctionComponent<{
  option: IWorkflowFormElementElementOption;
  path: (string | number)[];
  required?: boolean;
  label?: string,
}> = ({ option, path, required, label }) => {
  const name = useMemo(() => path ? [...path, option.key] : [option.key], [path, option]);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const InputComponent = useMemo(() => {
    switch (option.type) {
      case 'TEXT_AREA':
        return Input.TextArea;
      default:
        return Input;
    }
  }, [option.type]);
  const rules = useMemo(() => {
    const rules: Rule[] = [
      {
        required: required,
        message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
      },
      ...(option.maxLength != null ?
        [
          {
            max: option.maxLength,
            message: <ErrorMessage message={uiTexts?.MAXIMUM_CHARACTERS_EXCEEDED?.value} />,
          },
        ] : []),
    ];

    switch (option.pattern) {
      case WorkflowFormElementElementOptionPattern.Email:
        rules.push({
          pattern: RegexPattern.Email,
          message: <ErrorMessage message={uiTexts?.INPUT_PATTERN_EMAIL_INVALID?.value} />,
        });
        break;
    }

    return rules;
  }, [uiTexts, required, option]);

  return (
    <Form.Item
      label={<QuestionLabel>{label}</QuestionLabel>}
      name={name}
      rules={rules}
    >
      <InputComponent showCount={option.showRemaining} />
    </Form.Item>
  );
};

const TextFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const options = workflowElement.options;
  const { uiTexts } = useContext(CommencedWorkflowContext);

  return (
    <React.Fragment>
      {!options && (
        <Form.Item
          label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
          name={name}
          rules={[
            {
              required: workflowElement.required,
              message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
      )}
      {options?.map(option => (
        <ElementOption
          key={option.key}
          option={option}
          path={name}
          label={workflowElement.label || option.label}
          required={workflowElement.required}
        />

      ))}
    </React.Fragment>
  );
};

export default TextFormElement;
