import _ from 'lodash/fp';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useContext, useMemo } from 'react';
import { Checkbox, Form, Input, Space } from 'antd';
import { IWorkflowFormElementProps } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { QuestionLabel } from 'app/modules/pux/components/content';
import ErrorMessage from '../../../errorMessage';

const CheckboxFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, path } = props;
  const options = useMemo(() => _.sortBy('position', workflowElement?.options || []), [workflowElement]);
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const { uiTexts } = useContext(CommencedWorkflowContext);

  return (
    <React.Fragment>
      <Form.Item
        label={<QuestionLabel>{workflowElement.label}</QuestionLabel>}
        name={[...name, 'answers']}
        rules={[
          {
            required: workflowElement.required,
            message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
          },
        ]}
        getValueFromEvent={(args: string[]) => {
          for (const arg of args.reverse()) {
            // Handle last click being exclusive.
            if (options.find(option => option.key === arg)?.exclusive) {
              return [arg];
            }
            // Handle last click not being exclusive.
            if (!options.find(option => option.key === arg)?.exclusive) {
              return args.filter(arg => !options.find(option => option.key === arg)?.exclusive);
            }
          }

          return args;
        }}
      >
        <Checkbox.Group className="form-element-checkbox-group">
          <Space direction="vertical">
            {options.map(option => (
              <Checkbox
                className="form-element-checkbox"
                key={option.value}
                value={(option?.type === 'TEXT' && !option.key.endsWith('_other')) ? `${option.key}_other` : option.key}
              >
                <Form.Item
                  shouldUpdate={true}
                  noStyle={true}
                >
                  {form => {
                    if (!(option?.type === 'TEXT')) {
                      return option.label;
                    }

                    const value = form.getFieldValue([...name, 'answers']) as string[];
                    if (!(value || []).find((a) => a.endsWith('_other'))) {
                      return option.label;
                    }

                    return (
                      <Form.Item
                        name={[...name, 'other']}
                        rules={[
                          {
                            required: workflowElement.required,
                            message: <ErrorMessage message={uiTexts?.IS_REQUIRED?.value} />,
                          },
                          {
                            max: Number(option.limit),
                            message: <ErrorMessage message={uiTexts?.MAXIMUM_CHARACTERS_EXCEEDED?.value} />,
                          },
                        ]}
                        noStyle={true}
                      >
                        <Input className="form-element-checkbox-other-input" />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      </Form.Item>
    </React.Fragment>
  );
};

export default CheckboxFormElement;