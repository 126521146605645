import { Circle, Document, G, Page, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import React from 'react';

interface IProps {
  incentive: string;
  title?: string;
}

const styles = StyleSheet.create({
  page: {
  },
  body: {
    flexGrow: 1,
    padding: '4cm 2cm 2cm 2cm',
  },
  container: {
  },
  row: {
    flexDirection: 'row',
  },
  title: {
    fontSize: '12pt',
    fontWeight: 'normal',
    textAlign: 'center',
    width: '100%',
  },
  incentive: {
    fontSize: '24pt',
    fontWeight: 'bold',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1cm',
    padding: '0.5cm 1cm',
    backgroundColor: '#E7EBEF',
  },
  logo: {
    margin: 'auto',
  },
});

const IncentiveCodePdf: React.FunctionComponent<IProps> = ({
  incentive,
  title,
}) => {

  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
      >
        <View style={styles.body}>
          <View style={styles.container}>
            <View style={styles.row} >
              <View style={styles.title}>
                <Text>{title}</Text>
              </View>
            </View>
            <View style={styles.row} >
              <View style={styles.incentive}>
                <Text>{incentive}</Text>
              </View>
            </View>
            <View style={styles.row} >
              <View style={styles.logo}>
                <Svg
                  viewBox="330 340 1280 400"
                  width="200"
                  height="110"
                >
                  <G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M898.16,413.12v23.52c-31.05,0-46.17,13.37-46.17,62.28v49.99h-27V415.26h27v24.59 c8.64-18.71,23.49-26.73,45.63-26.73H898.16z"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M1021.01,492.78H916.79c3.51,24.86,20.25,35.82,39.42,35.55c16.2-0.27,31.86-8.02,39.15-25.66l23.22,9.09 c-10.8,25.93-35.64,38.49-62.37,38.76c-35.37,0.27-65.61-22.19-65.61-68.43c0-46.24,30.24-68.7,65.61-68.43 c35.37,0.27,65.61,22.19,65.61,68.43C1021.82,485.83,1021.55,489.31,1021.01,492.78z M916.79,470.59h78.84 c-4.05-24.32-20.52-34.48-39.42-34.75S920.84,446.27,916.79,470.59z"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M1157.36,492.78h-104.22c3.51,24.86,20.25,35.82,39.42,35.55c16.2-0.27,31.86-8.02,39.15-25.66l23.22,9.09 c-10.8,25.93-35.64,38.49-62.37,38.76c-35.37,0.27-65.61-22.19-65.61-68.43c0-46.24,30.24-68.7,65.61-68.43 c35.37,0.27,65.61,22.19,65.61,68.43C1158.17,485.83,1157.9,489.31,1157.36,492.78z M1053.14,470.59h78.84 c-4.05-24.32-20.52-34.48-39.42-34.75S1057.19,446.27,1053.14,470.59z"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M1165.46,373.15h26.73v175.77h-26.73V373.15z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Circle
                        cx="503.4"
                        cy="665.68"
                        r="41.25"
                        stroke="#FF7700"
                        fill="#FF7700"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M420.6,703.16h-46V557.92c0-78.85,64.15-143,143-143h265.89v46H517.6c-53.49,0-97,43.51-97,97V703.16z"
                      />
                    </G>
                    <G>
                      <Circle
                        cx="714.33"
                        cy="665.68"
                        r="41.25"
                        stroke="#FF7700"
                        fill="#FF7700"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M631.69,703.16h-46v-98.24c0-56.79,46.21-103,103-103h94.8v46h-94.8c-31.43,0-57,25.57-57,57V703.16z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M898.16,566.61v23.76c-31.05,0-46.17,13.5-46.17,62.91v50.49h-27v-135h27v24.84c8.64-18.9,23.49-27,45.63-27 H898.16z"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M1021.01,647.08H916.79c3.51,25.11,20.25,36.18,39.42,35.91c16.2-0.27,31.86-8.1,39.15-25.92l23.22,9.18 c-10.8,26.19-35.64,38.88-62.37,39.15c-35.37,0.27-65.61-22.41-65.61-69.12s30.24-69.39,65.61-69.12 c35.37,0.27,65.61,22.41,65.61,69.12C1021.82,640.06,1021.55,643.57,1021.01,647.08z M916.79,624.67h78.84 c-4.05-24.57-20.52-34.83-39.42-35.1C937.31,589.29,920.84,600.09,916.79,624.67z"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M1106.33,568.77h28.35l-50.49,135h-23.49l-50.49-135h28.35l34.02,100.17L1106.33,568.77z"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M1137.92,703.78h26.73v-135h-26.73V703.78z"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M1300.19,647.08h-104.22c3.51,25.11,20.25,36.18,39.42,35.91c16.2-0.27,31.86-8.1,39.15-25.92l23.22,9.18 c-10.8,26.19-35.64,38.88-62.37,39.15c-35.37,0.27-65.61-22.41-65.61-69.12s30.24-69.39,65.61-69.12 c35.37,0.27,65.61,22.41,65.61,69.12C1301,640.06,1300.73,643.57,1300.19,647.08z M1195.97,624.67h78.84 c-4.05-24.57-20.52-34.83-39.42-35.1C1216.49,589.29,1200.02,600.09,1195.97,624.67z"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M1445.18,568.77h27.54l-42.39,135h-22.68l-25.92-88.29l-25.92,88.29h-22.68l-42.39-135h27.54l25.65,92.61 l24.03-92.61h27.54l24.03,92.61L1445.18,568.77z"
                      />
                    </G>
                    <G>
                      <Path
                        stroke="#FF7700"
                        fill="#FF7700"
                        d="M1490.54,661.93c2.43,14.85,15.93,21.87,31.05,21.87c15.66,0,30.78-7.02,30.78-23.22 c0-30.24-85.05,4.05-85.05-52.65c0-38.88,39.96-41.04,54.27-41.04c13.5,0,48.06,1.89,54,31.86l-24.3,7.02 c-3.24-14.04-14.58-18.9-29.7-18.9c-15.39,0-27.81,5.4-27.81,21.06c0,31.32,85.05-4.86,85.05,52.65 c0,41.31-42.39,44.82-57.24,44.82c-13.5,0-52.38-3.24-56.7-38.07L1490.54,661.93z"
                      />
                    </G>
                  </G>
                  <G>
                    <Circle
                      cx="1223.49"
                      cy="529.29"
                      r="20.63"
                      stroke="#FF7700"
                      fill="#FF7700"
                    />
                  </G>
                </Svg>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default IncentiveCodePdf;
