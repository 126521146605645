import _ from 'lodash/fp';
import { message, Spin } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { workflowsActions } from 'app/modules/workflows';
import { IWorkflowElement, IWorkflowFormElement } from 'lib/modules/qualieApi/enities/workflow';
import { useCallback, useState } from 'react';
import WorkflowElement from '../../..';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import { Dictionary } from 'lib/types';

type Dependencies = {
  cameraTagOptions: IWorkflowElement;
};

const buildPayload = (elements: IWorkflowElement[], recordPayload: any) => elements.reduce<Dictionary>((acc, curr) => {
  switch (curr.name) {
    case 'url':
      acc[curr.name] = recordPayload.url;
      break;
    case 'uuid':
      acc[curr.name] = recordPayload.uuid;
      break;
    case 'opinionActivities':
      acc[curr.name] = curr.collection?.length ? _.values(buildPayload(curr.collection, recordPayload)) : [];
      break;
    default:
      acc[curr.name] = curr.collection?.length ? buildPayload(curr.collection, recordPayload) : curr.value;
  }

  return acc;
}, {});

const RecordOpinionCtFormWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElement, Dependencies>> = ({
  workflowElement,
  dependencies,
  onBeforeFinish,
  onFinish,
}) => {
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);

  const onRecordingFinish = useCallback(async (element: IWorkflowElement, recordPayload: any) => {
    if (onBeforeFinish && !(await onBeforeFinish(workflowElement))) {
      return;
    }

    const payload = buildPayload(workflowElement.elements, recordPayload);

    setProcessing(true);
    try {
      await dispatch(workflowsActions.submitWorkflowFormElement({
        actionUrl: workflowElement.actionURL,
        form: payload,
      })).unwrap();
    } catch (e: any) {
      setProcessing(false);
      message.error(e.message, 3);

      return;
    }

    setProcessing(false);
    onFinish?.(workflowElement);
  }, [dispatch, onBeforeFinish, onFinish, workflowElement]);

  return (
    <div>
      {!processing && (
        <WorkflowElement
          workflowElement={dependencies!.cameraTagOptions!}
          onFinish={onRecordingFinish}
        />
      )}
      {processing && <div className="workflow-record-opinion-processing"><Spin size="large" /></div>}
    </div>
  );
};

export default RecordOpinionCtFormWorkflowElement;
