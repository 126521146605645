import _ from 'lodash/fp';
import { IWorkflowElement } from 'lib/modules/qualieApi/enities/workflow';
import { useMemo } from 'react';
import { Dictionary } from 'lib/types';

const useKeyedWorkflowElements = <TElements extends Dictionary<IWorkflowElement>,>(elements: IWorkflowElement[]) => {
  const result = useMemo(() => _.keyBy('name', elements || []) as TElements, [elements]);

  return result;
};

const useSortedWorkflowElements = <TElement extends IWorkflowElement = IWorkflowElement>(elements?: TElement[]) => {
  const result = useMemo(() => _.sortBy('position', elements || []), [elements]);

  return result;
};

export {
  useKeyedWorkflowElements,
  useSortedWorkflowElements,
};