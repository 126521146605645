import { useCallback, useEffect, useRef, useState } from 'react';
import { useMountEffect } from '.';
import Cookie from 'js-cookie';

enum VideoRecorderStatus {
  Uninitialised = 'UNINITIALISED',
  FailedInitialisiation = 'FAILED_INITIALISATION',
  LoadMediaStream = 'LOAD_MEDIA_STREAM',
  Ready = 'READY',
  RecordingStart = 'RECORDING_START',
  Recording = 'RECORDING',
  RecordingStop = 'RECORDING_STOP',
  RecordingComplete = 'RECORDING_COMPLETE',
  NoSupportedCodec = 'NO_SUPPORTED_CODEC',
  NoPermissionGranted = 'NO_PERMISSION_GRANTED',
  UnknownError = 'UNKNOWN_ERROR',
};

type TargetDevices = {
  videoDeviceId?: string;
  audioDeviceId?: string;
};

const VIDEO_FPS = 30;
const VIDEO_RES = 1920;
const VIDEO_MBS = 8;

const DEFAULT_TARGET_DEVICES_COOKIE = 'qualie/video/targetDevices';
const DURATION_INTERVAL = 500;
const CODECS = [
  'video/webm;codecs=vp8,opus',
  'video/webm;codecs=vp8',
  'video/webm;codecs=av1,opus',
  'video/webm;codecs=av1',
  'video/webm;codecs=vp9,opus',
  'video/webm;codecs=vp9',
  'video/webm',
  'video/mp4',
];

const getOrientation = (): OrientationType => {
  const orientation = window.screen?.orientation;
  if (orientation?.type != null) {
    return orientation.type;
  }

  const angle = window.orientation;
  if (angle != null) {
    if (angle === 0) {
      return 'portrait-primary';
    }
    if (angle === 180) {
      return 'portrait-secondary';
    }
    if (angle === 90) {
      return 'landscape-primary';
    }
    if (angle === -90) {
      return 'landscape-secondary';
    }
  }

  return window.innerHeight > window.innerWidth ? 'portrait-primary' : 'landscape-primary';
};

const useVideoRecorder = () => {
  const mounted = useRef(true);
  const [status, setStatus] = useState<VideoRecorderStatus>(VideoRecorderStatus.Uninitialised);
  const [nextStatus, setNextStatus] = useState<VideoRecorderStatus>();
  const mediaStream = useRef<MediaStream | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const [videoBlob, setVideoBlob] = useState<Blob>();
  const [duration, setDuration] = useState(0);
  const [targetDevices, setTargetDevices] = useState<TargetDevices>({});

  const onCleanup = useCallback(async () => {
    if (mediaRecorder.current?.state !== 'inactive') {
      mediaRecorder.current?.stop();
    }

    mediaStream.current?.getTracks()?.forEach(a => a?.stop());
    mediaStream.current = null;
    mediaRecorder.current = null;
  }, []);

  const onChangeMediaStream = useCallback((stream: MediaStream) => {
    if (!mounted.current) {
      return;
    }

    let mime = CODECS.find(MediaRecorder.isTypeSupported);
    if (mime == null) {
      return setNextStatus(VideoRecorderStatus.NoSupportedCodec);
    }

    mediaStream.current = stream;
    mediaRecorder.current = new MediaRecorder(stream, {
      mimeType: mime,
      videoBitsPerSecond: VIDEO_MBS * 1000 * 1000,
    });
    mediaRecorder.current.ondataavailable = (e: BlobEvent) => {
      setVideoBlob(e.data);
    };

    setNextStatus(VideoRecorderStatus.Ready);
  }, []);

  const onGetDevices = useCallback(async () => {
    const devices = await window.navigator.mediaDevices.enumerateDevices();

    return devices;
  }, []);

  const onSetDevices = useCallback(async (devices: { videoDeviceId?: string; audioDeviceId?: string }) => {
    setTargetDevices(current => ({
      ...current,
      ...devices,
    }));
  }, []);

  const onGetMediaStream = useCallback(async () => {
    try {
      await onCleanup();
      const devices = await onGetDevices();
      const videoDeviceId = devices.find(a => a.deviceId === targetDevices.videoDeviceId) ? targetDevices.videoDeviceId : undefined;
      const audioDeviceId = devices.find(a => a.deviceId === targetDevices.audioDeviceId) ? targetDevices.audioDeviceId : undefined;

      const orientation = getOrientation();
      const ratio = orientation === 'portrait-primary' || orientation === 'portrait-secondary' ? 16 / 9 : 9 / 16;
      const resolutionConstraintKey = orientation === 'portrait-primary' || orientation === 'portrait-secondary' ? 'width' : 'height';

      const stream = (await window.navigator.mediaDevices.getUserMedia({
        video: {
          aspectRatio: {
            exact: ratio,
          },
          frameRate: {
            ideal: VIDEO_FPS,
          },
          [resolutionConstraintKey]: {
            ideal: VIDEO_RES,
          },
          deviceId: {
            ideal: videoDeviceId,
          },
        },
        audio: {
          deviceId: {
            ideal: audioDeviceId,
          },
        },
      }));

      onChangeMediaStream(stream);
    } catch (e: any) {
      switch (e?.name) {
        case 'NotAllowedError':
          setNextStatus(VideoRecorderStatus.NoPermissionGranted);
          break;
        default:
          setNextStatus(VideoRecorderStatus.UnknownError);
          console.error(e);
      }
    }
  }, [onCleanup, onChangeMediaStream, targetDevices, onGetDevices]);

  const onReady = useCallback(() => {
    setVideoBlob(undefined);
  }, []);

  const onStartRecording = useCallback(() => {
    if (mediaRecorder.current != null) {
      mediaRecorder.current.start();
      setDuration(0);
      setNextStatus(VideoRecorderStatus.Recording);
    }
  }, []);

  const onPauseRecording = useCallback(() => {
    mediaRecorder.current?.pause();
  }, []);

  const onResumeRecording = useCallback(() => {
    mediaRecorder.current?.resume();
    setNextStatus(VideoRecorderStatus.Recording);
  }, []);

  const onStopRecording = useCallback(() => {
    if (mediaRecorder.current != null) {
      mediaRecorder.current.stop();
      setNextStatus(VideoRecorderStatus.RecordingComplete);
    }
  }, []);

  const onStartRecordingExternal = useCallback(() => {
    setNextStatus(VideoRecorderStatus.RecordingStart);
  }, []);

  const onStopRecordingExternal = useCallback(() => {
    setNextStatus(VideoRecorderStatus.RecordingStop);
  }, []);

  const onResetExternal = useCallback(() => {
    setNextStatus(VideoRecorderStatus.Ready);
  }, []);

  useEffect(() => {
    if (nextStatus != null && status !== nextStatus) {
      setStatus(nextStatus);
      switch (nextStatus) {
        case VideoRecorderStatus.LoadMediaStream:
          onGetMediaStream();
          break;
        case VideoRecorderStatus.Ready:
          onReady();
          break;
        case VideoRecorderStatus.RecordingStart:
          onStartRecording();
          break;
        case VideoRecorderStatus.RecordingStop:
          onStopRecording();
          break;
      }
    }
  }, [
    status,
    nextStatus,
    onGetMediaStream,
    onReady,
    onStartRecording,
    onPauseRecording,
    onResumeRecording,
    onStopRecording,
  ]);

  useEffect(() => {
    if (status !== VideoRecorderStatus.Recording) {
      return;
    }

    const handle = setInterval(() => {
      setDuration(a => a + DURATION_INTERVAL);
    }, DURATION_INTERVAL);

    return () => {
      clearInterval(handle);
    };
  }, [status]);

  useEffect(() => {
    if (mediaStream.current != null) {
      setNextStatus(VideoRecorderStatus.LoadMediaStream);
      Cookie.set(DEFAULT_TARGET_DEVICES_COOKIE, JSON.stringify(targetDevices));
    }
  }, [targetDevices]);

  useMountEffect(() => {
    (async () => {
      setNextStatus(VideoRecorderStatus.LoadMediaStream);
      try {
        const cookieTargetDevices = Cookie.get(DEFAULT_TARGET_DEVICES_COOKIE);
        if (cookieTargetDevices != null) {
          const parsedTargetDevices = JSON.parse(cookieTargetDevices);
          setTargetDevices({
            audioDeviceId: typeof parsedTargetDevices.audioDeviceId === 'string' ? parsedTargetDevices.audioDeviceId : undefined,
            videoDeviceId: typeof parsedTargetDevices.videoDeviceId === 'string' ? parsedTargetDevices.videoDeviceId : undefined,
          });
        }
      } catch (e) {
        console.error(e);
      }
    })();

    return () => {
      mounted.current = false;
      onCleanup();
    };
  });

  return [
    onStartRecordingExternal,
    onStopRecordingExternal,
    onResetExternal,
    onGetDevices,
    onSetDevices,
    status,
    duration,
    mediaStream.current,
    videoBlob,
  ] as const;
};

export {
  VideoRecorderStatus,
  useVideoRecorder,
};