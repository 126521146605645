import { Spin } from 'antd';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { useKeyedWorkflowElements } from 'app/modules/pux/hooks';
import { IWorkflowElement, IWorkflowFormElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { WorkflowActivitySubtitle, WorkflowActivityTitle } from '../../content';
import BasicWorkflowForm from '../../layout/basicWorkflowForm';
import SplitWorkflowForm from '../../layout/splitWorkflowForm';
import WorkflowElement from '../../workflowElement';
import IWorkflowActivityProps from '../IWorkflowActivityProps';

type Elements = {
  title: IWorkflowElement;
  subTitle: IWorkflowElement;
  thankYouForm: IWorkflowFormElement;
  explainer: IWorkflowElement;
  completedURL: IWorkflowElement;
};

const ThankYouV3WorkflowActivity: React.FunctionComponent<IWorkflowActivityProps> = (props) => {
  const { workflowActivity } = props;
  const elements = useKeyedWorkflowElements<Elements>(workflowActivity.workflowElements);
  const { toNextStep, checkPrerequisites, onReady } = useContext(CommencedWorkflowContext);
  const autoSubmit = useMemo(() => elements.thankYouForm.elements?.find(a => a.name === 'auto_submit')?.value === 'true', [elements.thankYouForm]);
  const hasIncentive = useMemo(() => {
    const description = elements.thankYouForm.elements.find(a => a.name === 'display_code_description');
    const code = elements.thankYouForm.elements.find(a => a.name === 'display_code');

    return !!description && !!code;
  }, [elements.thankYouForm]);

  const onFinish = useCallback(() => {
    if (elements.completedURL?.value) {
      window.location.assign(elements.completedURL.value);
    } else {
      toNextStep();
    }
  }, [elements, toNextStep]);

  useEffect(() => {
    if (!autoSubmit) {
      onReady();
    }
  }, [autoSubmit, onReady]);

  return (
    <SplitWorkflowForm focus="empty">
      {!autoSubmit && (
        <SplitWorkflowForm.Filled>
          <WorkflowActivityTitle>{elements.title.value}</WorkflowActivityTitle>
          {!hasIncentive && <WorkflowActivitySubtitle>{elements.subTitle.value}</WorkflowActivitySubtitle>}
        </SplitWorkflowForm.Filled>
      )}
      <SplitWorkflowForm.Empty>
        {!autoSubmit && !hasIncentive && <WorkflowElement workflowElement={elements.explainer} />}
        <BasicWorkflowForm size="default">
          {!!autoSubmit && <div className="workflow-auto-submit-spin"><Spin size="large" /></div>}
          <WorkflowElement
            workflowElement={elements.thankYouForm}
            onFinish={onFinish}
            onBeforeFinish={checkPrerequisites}
          />
        </BasicWorkflowForm>
      </SplitWorkflowForm.Empty>
    </SplitWorkflowForm>
  );
};

export default ThankYouV3WorkflowActivity;
