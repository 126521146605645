import { Alert, Form } from 'antd';
import { FormElementContext } from 'app/modules/pux/contexts';
import { IWorkflowFormElementElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import IWorkflowElementProps from '../../../IWorkflowElementProps';
import { WORKFLOW_ELEMENT_FORM_ELEMENT_TYPE_COMPONENTS } from './types';
import { IWorkflowFormElementProps } from './types/types';

const InvalidTypeFallbackFormElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement } = props;
  const intl = useIntl();

  return (
    <Form.Item>
      <Alert
        type="error"
        message={intl.formatMessage({ id: 'workflowElement.form.invalidTypeFallback.title' }, { type: workflowElement.inputType })}
        showIcon
      />
    </Form.Item>
  );
};

const FormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { workflowElement, asType } = props;
  const { typeMapper } = useContext(FormElementContext);
  const WorkflowElementType = useMemo(() =>
    WORKFLOW_ELEMENT_FORM_ELEMENT_TYPE_COMPONENTS[asType || typeMapper?.(workflowElement) || (workflowElement.inputType ? workflowElement.inputType : workflowElement.type) || '']
    || InvalidTypeFallbackFormElement,
    [asType, workflowElement, typeMapper]
  );

  return (
    <WorkflowElementType {...props} />
  );
};

export default FormElement;
