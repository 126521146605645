import { useIsMobile, useMountEffect } from 'app/hooks';
import { IWorkflowElement, IWorkflowFormElementElement } from 'lib/modules/qualieApi/enities/workflow';
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import cx from 'classnames';
import { IWorkflowFormElementProps } from './types';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { Dictionary, FormElementName, QualieFile, StimulusType } from 'lib/types';
import { Alert, Button, Form, Modal } from 'antd';
import InitiativeWorkflowElement from '../../../initiative';
import PrerequisiteError from 'app/modules/pux/components/prerequisiteError';
import { imageStimulusTypes } from 'lib/types';

type Stimuli = {
  hash: string;
  id: number;
  name: string;
  position: number;
  qualieFile: QualieFile;
  stimulusType: StimulusType;
};

interface IInitiative extends IWorkflowElement {
  group: string;
  hash: string;
  id: number;
  internalName: string;
  name: string;
  stimuli: Stimuli[];
}

interface IStimuliModalProps {
  initiative: IInitiative;
  isOpen: boolean;
  onCancel: () => void;
  onValid: (element: IWorkflowElement, valid: boolean) => void;
  stimulusViewed: boolean;
}

const StimuliModal: React.FunctionComponent<IStimuliModalProps> = (props) => {
  const { initiative, isOpen, onCancel, onValid, stimulusViewed } = props;
  const { activityOptions } = useContext(CommencedWorkflowContext);
  const isMobile = useIsMobile();
  const wrapperClassName = useMemo(() => cx({
    'preference-stimuli-overlay-wrapper': true,
    'mobile-layout': isMobile,
  }), [isMobile]);
  const workflowElement: IWorkflowElement = useMemo(() => ({
    name: `initiatives-${initiative.hash}`,
    visible: true,
    workflowElementType: 'COLLECTION',
    collection: [initiative],
  }), [initiative]);

  useMountEffect(() => {
    if (activityOptions?.must_view_initiatives && !stimulusViewed) {
      onValid(workflowElement, false);
    }
  });

  return (
    <Modal
      className={wrapperClassName}
      centered
      visible={isOpen}
      footer={null}
      onCancel={onCancel}
      width="100%"
    >
      <InitiativeWorkflowElement
        workflowElement={workflowElement}
        onValid={onValid}
      />
    </Modal>
  );
};

interface IPreferenceContainerProps {
  hasFieldError: boolean;
  index: number;
  initiative: IInitiative;
  item: string;
  name: FormElementName;
  onClickPreferenceButton: (preference: string) => void;
  selected: boolean;
  setSelected: Dispatch<SetStateAction<Dictionary<boolean>>>;
}

const PreferenceContainer: React.FunctionComponent<IPreferenceContainerProps> = (props) => {
  const { hasFieldError, index, initiative, item, name, onClickPreferenceButton, selected, setSelected } = props;
  const [isStimuliModalOpen, setStimuliModalOpen] = useState<boolean>(false);
  const [stimulusViewed, setStimulusViewed] = useState<boolean>(false);
  const { activityOptions, prerequisites, setPrerequisite, uiTexts } = useContext(CommencedWorkflowContext);
  const stimuli = useMemo(() => _.sortBy(initiative.stimuli, ['position']), [initiative]);
  const wrapperClassName = useMemo(() => cx({
    'preference-container': true,
    'selected': selected,
    'has-error': !!prerequisites?.[`initiatives-${initiative.hash}`]?.reveal || hasFieldError,
  }), [selected, prerequisites, initiative.hash, hasFieldError]);
  const buttonClassName = useMemo(() => cx({
    'preference-select-btn': true,
    'selected': selected,
    'has-error': !!prerequisites?.[`initiatives-${initiative.hash}`]?.reveal || hasFieldError,
  }), [selected, prerequisites, initiative.hash, hasFieldError]);

  const onClickButton = useCallback(() => {
    setSelected(prevState => {
      const newState = { ...prevState };
      Object.keys(newState).forEach(v => newState[v] = false);

      return {
        ...newState,
        [item]: prevState[item] ? false : true,
      };
    });
    onClickPreferenceButton(item);
  }, [item, onClickPreferenceButton, setSelected]);

  const onInitiativesValid = useCallback((element: IWorkflowElement, valid: boolean) => {
    setStimulusViewed(valid);
    setPrerequisite(element.name, valid ? null : {
      valid: valid,
      error: (!valid && uiTexts?.VIEW_ALL_CONTENT?.value) || null,
      reveal: false,
    });
  }, [setPrerequisite, uiTexts]);

  const onCancel = useCallback(() => setStimuliModalOpen(false), [setStimuliModalOpen]);
  const onClickStimulus = useCallback(() => setStimuliModalOpen(true), [setStimuliModalOpen]);

  const thumbnailSrc = useMemo(() => {
    if (stimuli.length === 0) {
      return;
    }

    return imageStimulusTypes.includes(stimuli[0].qualieFile.type)
    ? stimuli[0].qualieFile.path
    : stimuli[0].qualieFile.thumbnail;
  }, [stimuli]);

  return (
    <Form.Item
      name={[...name, index]}
      noStyle
      shouldUpdate={true}
    >
      <div className="preference-container-wrapper">
        <div className={wrapperClassName}>
          <div className="preference-stimulus-wrapper">
            <div
              className="preference-stimulus-link"
              style={{ backgroundImage: `url('${thumbnailSrc}')` }}
            />
            <div className="preference-view-link-button">
              <Button
                type="link"
                onClick={onClickStimulus}
              >
                {uiTexts?.CLICK_TO_VIEW?.value}
              </Button>
            </div>
          </div>
          <div className="preference-choice-button">
            <Button
              className={buttonClassName}
              onClick={onClickButton}
              type="primary"
            >
              {selected ? uiTexts?.CHOSEN?.value : activityOptions.this_one_text}
            </Button>
          </div>
        </div>
        <div className="preference-initiative-text">
          <Form.Item shouldUpdate={true}>
            {form => {
              const error = form.getFieldError([...name, 'selected']);

              return (
                <React.Fragment>
                  {error.length > 0 && (
                    <Alert
                      showIcon
                      type="error"
                      className="prerequisite-error"
                      message={error}
                    />
                  )}
                  {error.length === 0 && <PrerequisiteError target={`initiatives-${initiative.hash}`} />}
                </React.Fragment>
              );
            }}
          </Form.Item>
        </div>
        <StimuliModal
          initiative={initiative}
          isOpen={isStimuliModalOpen}
          onCancel={onCancel}
          onValid={onInitiativesValid}
          stimulusViewed={stimulusViewed}
        />
      </div>
    </Form.Item>
  );
};

const PreferenceSingleOpinionFormElement: React.FunctionComponent<IWorkflowFormElementProps<IWorkflowFormElementElement>> = (props) => {
  const { dependencies, workflowElement, path } = props;
  const name = useMemo(() => path ? [...path, workflowElement.name] : [workflowElement.name], [path, workflowElement]);
  const isMobile = useIsMobile();
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const [selected, setSelected] = useState<Dictionary<boolean>>({});
  const wrapperClassName = useMemo(() => cx({
    'preference-single-opinion-form-element': true,
    'mobile-layout': isMobile,
  }), [isMobile]);
  const initiatives = dependencies?.initiatives?.collection;

  const rules = useMemo(() => ([
    {
      required: true,
      message: uiTexts?.REQUIRED?.value,
    },
  ]), [uiTexts]);

  return (
    <Form.Item
      className={wrapperClassName}
      shouldUpdate={true}
    >
      {form => {
        const value = form.getFieldValue(name);
        const error = form.getFieldError([...name, 'selected']);

        const onClickPreferenceButton = (preference: string) => {
          form.setFieldsValue(_.set({}, [...name, 'selected'], (value.selected === preference) ? null : preference));
        };

        return (
          <Form.Item
            name={[...name, 'selected']}
            rules={rules}
            noStyle={true}
          >
            <div className="form-element-wrapper">
              {value.preferences.map((item: string, index: number) => {
                const matchedInitiative = _.find(initiatives, { hash: item }) as IInitiative;
                const isSelected = selected[item] || false;

                if (matchedInitiative.stimuli.length === 0) {
                  return null;
                }

                return (
                  <div
                    className="form-element-container"
                    key={`preference-${index}`}
                  >
                    <PreferenceContainer
                      hasFieldError={error.length > 0}
                      onClickPreferenceButton={onClickPreferenceButton}
                      index={index}
                      initiative={matchedInitiative}
                      item={item}
                      key={item}
                      name={name}
                      selected={isSelected}
                      setSelected={setSelected}
                    />
                  </div>
                );
              })}
            </div>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default PreferenceSingleOpinionFormElement;
