import { Alert } from 'antd';
import { IWorkflowExternalVideoElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import IWorkflowElementProps from '../../IWorkflowElementProps';
import { WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPE_COMPONENTS } from './types';

const InvalidTypeFallbackExternalVideoWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowExternalVideoElement>> = (props) => {
  const { workflowElement } = props;
  const intl = useIntl();

  return (
    <Alert
      showIcon
      type="error"
      message={intl.formatMessage({ id: 'workflowElement.externalVideo.invalidProviderFallback.title' }, { type: workflowElement.provider })}
    />
  );
};

const ExternalVideoWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowExternalVideoElement>> = (props) => {
  const { workflowElement } = props;
  const WorkflowElementType = useMemo(() => WORKFLOW_ELEMENT_EXTERNAL_VIDEO_TYPE_COMPONENTS[workflowElement.provider] || InvalidTypeFallbackExternalVideoWorkflowElement, [workflowElement]);

  return <WorkflowElementType {...props} />;
};

export default ExternalVideoWorkflowElement;
