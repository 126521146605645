import _ from 'lodash/fp';
import { Alert, Carousel } from 'antd';
import { IWorkflowElement, IWorkflowExternalVideoElement, IWorkflowImageElement, IWorkflowInitiativeElement } from 'lib/modules/qualieApi/enities/workflow';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import IWorkflowElementProps from '../IWorkflowElementProps';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { WORKFLOW_ELEMENT_TYPES } from '.';
import WorkflowElement from '..';
import { CommencedWorkflowContext } from 'app/modules/pux/contexts';
import { Dictionary, QualieFile } from 'lib/types';
import ExternalVideoWorkflowElement from './externalVideo';
import ImageWorkflowElement from './image';

enum CAROUSEL_SLIDE_TYPES {
  Video = 'VIDEO',
  Image = 'IMAGE',
};

interface IInitiativeGroupStimulus {
  id: number;
  hash: string;
  name: string;
  position: number;
  stimulusType: string;
  qualieFile: QualieFile;
}

interface IInitiativeGroup {
  stimuli: IInitiativeGroupStimulus[];
}

const ARROW_TYPES = {
  'left': LeftOutlined,
  'right': RightOutlined,
};

interface ICarouselArrowProps {
  type: keyof typeof ARROW_TYPES;
  currentSlide?: number;
  onClick?: () => void;
  className?: string;
}

const CarouselArrow: React.FunctionComponent<ICarouselArrowProps> = (props) => {
  const { type, className, onClick } = props;
  const Arrow = ARROW_TYPES[type];

  return (
    <div
      className={className}
      onClick={onClick}
    >
      <Arrow className="carousel-arrow" />
    </div>
  );
};

const CarouselSlide: React.FunctionComponent<{
  slide: IInitiativeGroupStimulus,
  onValid: IWorkflowElementProps['onValid'],
  active: boolean,
}> = (props) => {
  const { slide, onValid, active } = props;
  const intl = useIntl();
  const { activityOptions } = useContext(CommencedWorkflowContext);
  const skipValidation = activityOptions?.must_view_initiatives === false;
  const workflowElement = useMemo(() => {
    switch (slide.stimulusType) {
      case CAROUSEL_SLIDE_TYPES.Video:
        return {
          name: slide.qualieFile.name,
          visible: true,
          workflowElementType: WORKFLOW_ELEMENT_TYPES.ExternalVideo,
          provider: slide.qualieFile.videoProvider,
          width: slide.qualieFile.width,
          height: slide.qualieFile.height,
          thumbnail: slide.qualieFile.thumbnail,
          videoId: slide.qualieFile.key,
          position: (String)(slide.position),
        } as IWorkflowExternalVideoElement;
      case CAROUSEL_SLIDE_TYPES.Image:
        return {
          name: slide.qualieFile.name,
          visible: true,
          workflowElementType: WORKFLOW_ELEMENT_TYPES.Image,
          sourceUri: slide.qualieFile.sourceUri,
          path: slide.qualieFile.path,
          position: (String)(slide.position),
        } as IWorkflowImageElement;
      default:
        return null;
    }
  }, [slide]);

  useEffect(() => {
    if (onValid && workflowElement && skipValidation) {
      onValid(workflowElement, true);
    }
  }, [onValid, workflowElement, skipValidation]);

  if (!workflowElement) {
    return (
      <div className="workflow-element-initiative-slide">
        <Alert
          type="error"
          message={intl.formatMessage({ id: 'workflowElement.invalidTypeFallback.title' }, { type: slide.stimulusType })}
        />
      </div>
    );
  }

  switch (slide.stimulusType) {
    case CAROUSEL_SLIDE_TYPES.Video:
      return (
        <div className="workflow-element-initiative-slide">
          <ExternalVideoWorkflowElement
            workflowElement={workflowElement as any}
            onValid={skipValidation ? undefined : onValid}
          />
        </div>
      );
    case CAROUSEL_SLIDE_TYPES.Image:
      return (
        <div className="workflow-element-initiative-slide">
          <ImageWorkflowElement
            workflowElement={workflowElement as any}
            onValid={skipValidation ? undefined : onValid}
            active={active}
          />
        </div>
      );
    default:
      return (
        <div className="workflow-element-initiative-slide">
          <WorkflowElement
            workflowElement={workflowElement}
            onValid={skipValidation ? undefined : onValid}
          />
        </div>
      );
  }
};

const InitiativeWorkflowElement: React.FunctionComponent<IWorkflowElementProps<IWorkflowInitiativeElement>> = (props) => {
  const { workflowElement, onValid } = props;
  const initiative = ((workflowElement.collection || [])[0] as any) as IInitiativeGroup;
  const stimuli = useMemo(() => _.sortBy('position', initiative?.stimuli || []), [initiative]);
  const initialElementValues = useMemo(() => {
    let initialValues: Dictionary<boolean> = {};
    stimuli.forEach((value: IInitiativeGroupStimulus, index: number) => initialValues[value.position] = false);

    return initialValues;
  }, [stimuli]);
  const [validElements, setValidElements] = useState<Dictionary<boolean>>(initialElementValues);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const afterChange = useCallback((currentSlide: number) => {
    setActiveIndex(currentSlide);
  }, []);

  const onValidSlide = useCallback((workflowElement: IWorkflowElement, valid: boolean) => {
    setValidElements(validElements => ({
      ...validElements,
      [workflowElement.position!]: valid,
    }));
  }, [setValidElements]);

  useEffect(() => {
    if (onValid) {
      const isValid = stimuli.length === 0 || (!!validElements && _.values(validElements).filter(a => !a).length === 0);
      onValid(workflowElement, isValid);
    }
  }, [stimuli, workflowElement, validElements, onValid]);

  return (
    <div className="workflow-element-initiative">
      <Carousel
        afterChange={afterChange}
        className={stimuli.length > 1 ? 'with-arrows' : undefined}
        dotPosition="top"
        dots={stimuli.length > 1}
        arrows={stimuli.length > 1}
        autoplay={false}
        prevArrow={<CarouselArrow type="left" />}
        nextArrow={<CarouselArrow type="right" />}
        slidesToShow={1}
        infinite={false}
      >
        {stimuli.map((stimulus, index) => (
          <CarouselSlide
            key={stimulus.position}
            slide={stimulus}
            onValid={onValidSlide}
            active={index === activeIndex}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default InitiativeWorkflowElement;
