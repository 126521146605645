import { Button } from 'antd';
import { IWorkflowElement } from 'lib/modules/qualieApi/enities/workflow';
import { Dictionary } from 'lib/types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { CommencedWorkflowContext } from '../contexts';
import cx from 'classnames';

const getUiTextValue = (uiTexts: Dictionary<IWorkflowElement>, key: string, fallback: string) => uiTexts[key] || fallback;
const getIsTouchDevice = () => navigator.maxTouchPoints > 0;

interface IProps { }

const DragConfirmOverlay: React.FunctionComponent<IProps> = ({
  children,
}) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const { uiTexts } = useContext(CommencedWorkflowContext);
  const className = useMemo(() => cx({
    'drag-confirm-wrapper': true,
    'confirmed': confirmed,
  }), [confirmed]);
  const confirmLabel = useMemo(() => getUiTextValue(
    uiTexts!,
    getIsTouchDevice() ? 'TOUCH_CONFIRM_LABEL_V2' : 'DRAG_CONFIRM_LABEL',
    'Touch and hold an item until the grey background appears, then you can drag it to sort it.'
  ), [uiTexts]);
  const confirmButtonLabel = useMemo(() => getUiTextValue(
    uiTexts!,
    'TOUCH_CONFIRM_BUTTON_LABEL',
    'Got it!'
  ), [uiTexts]);
  const onClick = useCallback(() => setConfirmed(true), []);

  return (
    <div className={className}>
      {!confirmed && <div className="drag-confirm-overlay">
        <div className="drag-confirm-overlay-content">
          <div className="drag-confirm-overlay-label">{confirmLabel?.value}</div>
          <Button onClick={onClick}>{confirmButtonLabel?.value}</Button>
        </div>
      </div>}
      <span className="drag-confirm-content">
        {children}
      </span>
    </div>
  );
};

export default DragConfirmOverlay;
